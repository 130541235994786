import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import $ from "jquery";
// import appStore from '../Images/ios_download.jpeg';
// import googlePlay from '../Images/play_download.jpeg';
// import SasAdComponent from "./Ads/SasAdComponent";
// import { isMobileOnly } from 'react-device-detect';
// import LoadingPost from '../Images/loading.jpg';
// import Img from 'react-cool-img';
import { Link } from "react-router-dom";
// import playbutton from '../Images/play-button.png';
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;

class ArticlesSectionQueer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      matches: window.matchMedia("(min-width: 821px)").matches,
      numColums: "",
      width: "",
      height: "",
      colors: ["pink", "blue", "orange", "yellow", "green", "red"],
      SiteId: "391880",
      PageId: "",
    };
  }

  componentWillUnmount() {
    flagApi = false;
  }

  componentDidMount() {
    if (!flagApi) {
      this.loadPosts(1);
      if ($(window).width() < 768) {
        $(".caro-image").addClass("offset-2");
      }
      const handler = (e) => this.setState({ matches: e.matches });
      this.resizeListener = window.addEventListener("resize", this.columnsCalc);
      this.columnsCalc();
      window
        .matchMedia("(min-width: 768px)")
        .addEventListener("change", handler);
      flagApi = true;
    }
  }

  columnsCalc = () => {
    // console.log("deberia cambiar el display de columnas")
    let screenDivision = Math.floor(window.screen.width / 300);
    // console.log(screenDivision);
    let columns;
    switch (screenDivision) {
      case 1:
        columns = 12 / 1;
        break;
      case 2:
        columns = 12 / 2;
        break;
      case 3:
        columns = 12 / 3;
        break;
      case 4:
        columns = 12 / 4;
        break;
      case 5:
        columns = 12 / 4;
        break;
      case 6:
        columns = 12 / 6;
        break;
      case 7:
        columns = 12 / 6;
        break;
      case 8:
        columns = 12 / 6;
        break;
      case 9:
        columns = 12 / 6;
        break;
      case 10:
        columns = 12 / 6;
        break;
      case 11:
        columns = 12 / 6;
        break;
      case 12:
        columns = 12 / 12;
        break;
      case 13:
        columns = 12 / 12;
        break;
      default:
        break;
    }
    let sizeWH = window.innerWidth / screenDivision - 38;
    this.setState({ numColums: columns, width: sizeWH, height: sizeWH });
  };

  loadPosts = async (pageNumber) => {
    this.setState({ Loading: true });
    const pageId = getPageId("queer");
    var apipath = `/get-rnd-posts-from-inner-category?category=queer`;

    try {
      const res = await axios.get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      });

      const self = this;
      if (this.state.matches) {
        Promise.resolve().then(() => {
          const content = self.renderTopAd(119619);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119619_11111").html("");
          $("#bannerAd_sas_119619_11111").append(content);
        });
      }

      this.setState({
        totalPages: res.data.pagecount,
        activePage: pageNumber,
        Posts: res.data[0],
        Loading: false,
        PageId: pageId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // loadPosts = async (pageNumber) => {
  //   this.setState({ Loading: true });
  //   const pageId = getPageId("queer");
  //   var apipath = `/get-rnd-posts-from-inner-category?category=queer`;
  //   await axios
  //     .get(`${apipath}`, {
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res.data[0])
  //       const self = this;
  //       // setTimeout(function () {
  //       //   const content = self.getAdFunction(pageId);
  //       //   var div = document.createElement('span');
  //       //   div.innerHTML = content;

  //       //       $('#ads-rendered').html('');
  //       //       $('#ads-rendered').append(content);
  //       // }, 100);
  //       if (this.state.matches) {
  //         setTimeout(function () {
  //           const content = self.renderTopAd(119619);
  //           var div = document.createElement("span");
  //           div.innerHTML = content;
  //           $("#bannerAd_sas_119619_11111").html("");
  //           $("#bannerAd_sas_119619_11111").append(content);
  //         }, 100);
  //       }
  //       // else{
  //       //   setTimeout(function () {
  //       //     const content = self.renderTopAd(119621);
  //       //     var div = document.createElement('span');
  //       //     div.innerHTML = content;
  //       //         $('#bannerAd_sas_119621_11111').html('');
  //       //         $('#bannerAd_sas_119621_11111').append(content);
  //       //   }, 100);
  //       // }
  //       console.log(res.data[0]);
  //       this.setState({
  //         totalPages: res.data.pagecount,
  //         activePage: pageNumber,
  //         Posts: res.data[0],
  //         Loading: false,
  //         PageId: pageId,
  //         // listScript: StructuredDataListScript(
  //         //   res.data[0],
  //         //   "",
  //         //   "From our series",
  //         //   "Home Page"
  //         // ),
  //       });
  //       // console.log(this.state.Posts)
  //       // this.asignColors();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // console.log(this.state)
  // };

  appendAdsToPost(post) {
    // console.log(post);
    if (!post.adAppended) {
      post.randDivIdBot = parseInt(Math.random() * 1000000000);
      // console.log("le va a hacer append a el post", post);
      post.adAppended = true;
      const pageId = this.state.PageId;
      const siteId = this.state.SiteId;

      const bot_content_formats = ["119280"];

      bot_content_formats.forEach((format) => {
        setTimeout(() => {
          this.appendAd(post.randDivIdBot, format, pageId, siteId, "bot");
        }, 500);
      });
    }
  }

  loadAd(index) {
    var rndId = parseInt(Math.random() * 1000000);

    var art = document.createElement("div");
    $(art).addClass("article");

    // var artTitle = document.createElement("h2");
    // $(artTitle).text("Title Article "+ rndId);
    // art.appendChild(artTitle);

    var artBanner = document.createElement("div");
    artBanner.id = "bannerAd_" + rndId;
    // art.appendChild(artBanner);
    $(`#articles_${index}`).append(artBanner);

    // var artBody = document.createElement("div");
    // $(artBody).height(200);

    // var artSky = document.createElement("div");
    // artSky.id = 'skyAd_' + rndId;
    // $(artSky).css('float', 'right');
    // artBody.appendChild(artSky);

    // var artContent = document.createElement("div");
    //$(artContent).css('float', 'left').width(850);
    // $(artContent).text("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget mi pellentesque, vehicula odio eget, commodo velit. Integer fermentum enim sit amet elit dictum rhoncus. Nunc quis molestie nunc, in dignissim velit. Ut sollicitudin finibus turpis. Aliquam pharetra lectus ac scelerisque aliquet. Curabitur ornare purus in porta fringilla. Nam non finibus libero. Donec quis tortor non nibh efficitur pellentesque. Etiam est risus, vestibulum eu eleifend non, luctus et justo. Cras interdum non purus venenatis accumsan.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget mi pellentesque, vehicula odio eget, commodo velit. Integer fermentum enim sit amet elit dictum rhoncus. Nunc quis molestie nunc, in dignissim velit. Ut sollicitudin finibus turpis. Aliquam pharetra lectus ac scelerisque aliquet. Curabitur ornare purus in porta fringilla. Nam non finibus libero. Donec quis tortor non nibh efficitur pellentesque. Etiam est risus, vestibulum eu eleifend non, luctus et justo. Cras interdum non purus venenatis accumsan.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget mi pellentesque, vehicula odio eget, commodo velit. Integer fermentum enim sit amet elit dictum rhoncus. Nunc quis molestie nunc, in dignissim velit. Ut sollicitudin finibus turpis. Aliquam pharetra lectus ac scelerisque aliquet. Curabitur ornare purus in porta fringilla. Nam non finibus libero. Donec quis tortor non nibh efficitur pellentesque. Etiam est risus, vestibulum eu eleifend non, luctus et justo. Cras interdum non purus venenatis accumsan.");
    // artBody.appendChild(artContent);

    // art.appendChild(artBody);

    var option = "{ resetTimestamp: true }";
    //option = "{}";
    //119621
    var smartTags = document.createElement("script");
    var code =
      "sas.cmd.push(function() {sas.call('std', {siteId:391880,pageId:1743691,formatId:119280,tagId:'bannerAd_" +
      rndId +
      "'}, " +
      option +
      ");});";
    // code += "sas.cmd.push(function() {sas.call('std', {siteId:35227,pageId:1743691,formatId:119621,tagId:'skyAd_" + rndId + "'});});"
    $(smartTags).text(code);
    $(`#articles_${index}`).append(smartTags);

    // art.appendChild(smartTags);
    // $(`#articles_${index}`).append(art);
  }

  //Type can be top/bot/side
  appendAd(divId, format, pageId, siteId, type) {
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;

    var art = document.createElement("div");
    $(art).addClass(`${type}-adverties`);

    // Create a container in the article with the SMART tag
    var smartDiv = document.createElement("div");
    smartDiv.id = "bannerAd_" + rndId;

    var option = "{ resetTimestamp: true }";
    var smartTag = document.createElement("script");
    var code =
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:'bannerAd_` +
      rndId +
      "'}, " +
      option +
      ");});";

    $(smartTag).text(code);
    art.appendChild(smartDiv);
    art.append(smartTag);

    // Directly append the ad content without waiting
    $("#" + divId).append(art);
  }

  // appendAd(divId, format, pageId, siteId, type) {
  //   // console.log(divId, format, pageId, siteId, type);
  //   var rndId = parseInt(Math.random() * 1000000);
  //   rndId = `sas_${format}_${rndId}`;
  //   var art = document.createElement("div");
  //   // console.log(art)
  //   $(art).addClass(`${type}-adverties`);
  //   // Create a container in the article with the SMART tag

  //   var smartDiv = document.createElement("div");
  //   smartDiv.id = "bannerAd_" + rndId;

  //   var option = "{ resetTimestamp: true }";
  //   var smartTag = document.createElement("script");
  //   var code =
  //     `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:'bannerAd_` +
  //     rndId +
  //     "'}, " +
  //     option +
  //     ");});";
  //   // $(smartTag).text(`sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:${rndId}});});`);
  //   $(smartTag).text(code);
  //   art.appendChild(smartDiv);
  //   art.append(smartTag);
  //   // const dIv = $("#"+divId)
  //   // console.log(dIv)
  //   $("#" + divId).append(art);

  //   // currentAdsIds.push(rndId);

  //   // var refreshContentSc = document.createElement("script");
  //   // $(refreshContentSc).text( "localStorage.setItem('startDate"+rndId+"', 'start'); localStorage.setItem('temp"+rndId+"', '"+rndId+"'); localStorage.setItem('tempcount"+rndId+"', 0);   setInterval(function(){var countsAds=0; var top_of_element = $('#"+rndId+"').offset().top; var bottom_of_element = $('#"+rndId+"').offset().top + $('#"+rndId+"').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate"+rndId+"'); if(lastsyncdata=='start'){ localStorage.setItem('startDate"+rndId+"', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate"+rndId+"')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount"+rndId+"');  var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp"+rndId+"');   if(loopCount<5 && Diffseconds>30){ sas.cmd.push(function() {sas.refresh("+rndId+");}); localStorage.setItem('tempcount"+rndId+"', (Number(localStorage.getItem('tempcount"+rndId+"'))+1)); localStorage.setItem('startDate"+rndId+"', new Date()); }}},1000)");
  //   // $("#"+divId).append(refreshContentSc);
  // }

  getAdFunction(pageId) {
    const adFunction = `
      <script>
        var sas = sas || {};
        sas.cmd = sas.cmd || [];
        sas.cmd.push(function() {
            sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
        });
        sas.cmd.push(function() {
            sas.call("onecall", {
                siteId: 391880,
                pageId: ${pageId},
                formats: [
                    { id: 119311 }
                  ,{ id: 119312 }
                  ,{ id: 119313 }
                  ,{ id: 119314 }
                  ,{ id: 119620 }
                  ,{ id: 119619}
                ],
                target: ''
            });
        });
      </script>
    `;
    return adFunction;
  }

  renderTopAd(adId) {
    const topAdFunction = `
    <script>sas.cmd.push(function() 
    {sas.call('std', {siteId:391880,pageId:1701583,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
    `;
    return topAdFunction;
  }

  render() {
    const { Loading, Posts, Pages, numColums, width, height, colors } =
      this.state;
    let contador = 0;
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script> */}
          {/* <script type="application/ld+json">
            {JSON.stringify(this.state.listScript)}
          </script> */}
        </Helmet>
        <section
          id="gallery-section"
          className="gallery-section"
          style={{
            ...(!this.state.matches
              ? { marginBottom: `0` }
              : { marginBottom: `45px` }),
          }}
        >
          <div className="container queer">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <h2 className="sub-title" style={{ padding: "30px 0 30px 0" }}>
                  OUR LATEST ARTICLES
                </h2>
                {/* falta un margen arriba y el color negro en la fuente */}
              </div>
            </div>
            <div className="row" data-masonry='{"percentPosition": true }'>
              {!Loading ? (
                Object.keys(Posts).map((category) => {
                  // console.log(category);
                  let tamaño = Posts[category].length - 1;
                  // console.log(tamaño);
                  const internMap = Posts[category].map((post, index) => {
                    // console.log(index);
                    // console.log(post)
                    // console.log(index,colors.length)
                    if (index === 0) {
                      this.appendAdsToPost(post);
                    }
                    if (index === 0) {
                      contador++;
                    } else if (contador === colors.length - 1) {
                      contador = 0;
                    }
                    const {
                      ID,
                      post_category,
                      post_image,
                      post_title,
                      post_slug,
                      publish_date,
                      post_type,
                      imgs,
                      short_description,
                      randDivIdBot,
                    } = post;
                    if (post_type === "post") {
                      return (
                        <>
                          {index === 0 ? (
                            <div
                              className={"col-" + numColums}
                              style={{ marginBottom: "30px" }}
                            >
                              <div>
                                <div
                                  className={
                                    "square categoory " + colors[contador]
                                  }
                                  sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                >
                                  <div className="text">
                                    <h3>{category}</h3>
                                  </div>
                                </div>
                                <div
                                  className="bot-adverties"
                                  // style={{
                                  //   backgroundColor: "rgb(237, 56, 151, 0.1)",
                                  //   height: "250px",
                                  //   border: "1px solid #ED3897",
                                  // }}
                                >
                                  <p style={{ visibility: "hidden" }}>
                                    sas ads bot
                                  </p>
                                  <div id={post.randDivIdBot}> </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className={"col-" + numColums} key={ID}>
                            <div className="square">
                              <div>
                                <Link to={`/queer/${post_slug}/`}>
                                  <div
                                    className={
                                      "articles-image img-border-" +
                                      colors[contador]
                                    }
                                  >
                                    <img
                                      sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                      srcSet={imgs
                                        .toReversed()
                                        .map((image, index) => {
                                          if (image[1] === undefined) {
                                            return [];
                                          }
                                          const Srcset = [];
                                          const Zize = image[1].toString();
                                          const link = image[0].toString();
                                          Srcset.push(link + " " + Zize + "w");
                                          return Srcset;
                                        })}
                                      src={post_image.medium_image_url}
                                      width="1000px"
                                      height="563px"
                                      // cache
                                      className="img-fluid img-responsive w-100 img-sizing"
                                      alt="alt"
                                    />
                                  </div>
                                  <div className="bottomRead-Content">
                                    <div className="bottom-content black-text">
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html: post_title,
                                        }}
                                      ></h5>
                                    </div>
                                    <div className="bottom-content black-text line-clamp">
                                      <p className="queer">
                                        {short_description}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* {index === tamaño ? (
                            <div className={"col-" + numColums}>
                              <div className="bot-adverties">
                                <p style={{ visibility: "hidden" }}>
                                  sas ads bot
                                </p>
                                <div id={post.randDivIdBot}> </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )} */}
                          {/* {index === tamaño ? (
                            <div className={"col-" + numColums}>
                              <div className="bot-adverties">
                                <p style={{ visibility: "hidden" }}>
                                  sas ads bot
                                </p>
                                <div id={post.randDivIdBot}> </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )} */}
                        </>
                      );
                    } else if (post_type === "watch") {
                      return (
                        <>
                          {index === 0 ? (
                            <div
                              className={"col-" + numColums}
                              style={{ marginBottom: "30px" }}
                            >
                              <div>
                                <div
                                  className={
                                    "square categoory " + colors[contador]
                                  }
                                  sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                >
                                  <div className="text">
                                    <h3>{category}</h3>
                                  </div>
                                </div>
                                <div
                                  className="bot-adverties"
                                  // style={{
                                  //   backgroundColor: "rgb(237, 56, 151, 0.1)",
                                  //   height: "250px",
                                  //   border: "1px solid #ED3897",
                                  // }}
                                >
                                  <p style={{ visibility: "hidden" }}>
                                    sas ads bot
                                  </p>
                                  <div id={post.randDivIdBot}> </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className={"col-" + numColums} key={ID}>
                            <div className="square">
                              <div>
                                <Link to={`/queer/watch/${post_slug}/`}>
                                  {/* <a href={`../watch/${post_slug}`}> */}
                                  <div className="">
                                    <div className="side-video-thumbnail">
                                      <img
                                        sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                        srcSet={imgs
                                          .toReversed()
                                          .map((image, index) => {
                                            if (image[1] === undefined) {
                                              return [];
                                            }
                                            const Srcset = [];
                                            const Zize = image[1].toString();
                                            const link = image[0].toString();
                                            Srcset.push(
                                              link + " " + Zize + "w"
                                            );
                                            return Srcset;
                                          })}
                                        // src={post_image.medium_image_url}
                                        src={post_image.medium_image_url}
                                        loading="lazy"
                                        cache
                                        width="1000px"
                                        height="563px"
                                        className="img-fluid w-100 h-auto img-sizing"
                                        alt={post_image.image_alt}
                                      />
                                      {/* <div><img src={playbutton} className="img-fluid" alt={'play-button'}/></div> */}
                                    </div>
                                    <div className="bottomRead-Content">
                                      <div className="bottom-content black-text">
                                        <h5
                                          dangerouslySetInnerHTML={{
                                            __html: post_title,
                                          }}
                                        ></h5>
                                      </div>
                                    </div>
                                    <div className="bottom-content black-text line-clamp">
                                      <p className="queer">
                                        {short_description}
                                      </p>
                                    </div>
                                  </div>
                                  {/* </a> */}
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* {index === tamaño ? (
                            <div className={"col-" + numColums}>
                              <div className="bot-adverties">
                                <p style={{ visibility: "hidden" }}>
                                  sas ads bot
                                </p>
                                <div id={post.randDivIdBot}> </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )} */}
                        </>
                      );
                    }
                  });
                  return internMap;
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="flex-center">
            {
              this.state.matches ? (
                <div id="6644">
                  <div class="top-adverties">
                    <div
                      id="bannerAd_sas_119619_11111"
                      className="Ad119619"
                    ></div>
                  </div>
                </div>
              ) : (
                <></>
              )
              // <SasAdComponent sasId={"119621"}/>
              // <div class="top-adverties">
              //   <div id="bannerAd_sas_119621_11111"></div>
              // </div>
            }
          </div>
        </section>
      </>
    );
  }
}

export default ArticlesSectionQueer;
