import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import mediastaticpages from "../../apiConnections/mediastaticpages";
import axiosstaticpages from "../../apiConnections/axiosstaticpages";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { useParams } from "react-router-dom";
import SpecialsArticlesLoadComponent from "../SeriesComponents/SpecialsArticlesLoadComponent";
import ContextlyLoadComponent from "../SeriesComponents/ContextlyLoadComponent";
import Carousel from "../Carousel/CarouselComponent";
import ArticlesLoadComponent from "../SeriesComponents/ArticlesLoadComponent";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;
let flagApi2 = false;

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

export class DigitalSeriesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      LoadingArticles: false,
      LoadingGuests: false,
      LoadingExperts: false,
      LoadingStorytellers: false,
      Loadingcss: true,
      MailChimpUrl: "https://eepurl.com/h-xXin",
      title: "",
      content: "",
      renderedcontent: "",
      cssfile: "",
      showHide: false,
      showHideNotify: false,
      showHideSchedule: false,
      scheduleContent: "",
      hasGuests: false,
      hasExperts: false,
      hasStorytellers: false,
      mainPostCategory: "",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
      contentArticles: [],
      contentGuests: [],
      contentExperts: [],
      width: 0,
      height: 0,
      cname: "",
      email: "",
      phone: "",
      message: "",
      formIsValid: true,
      nameError: "",
      emailError: "",
      phoneError: "",
      messageError: "",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  handleModalShowHideNotify() {
    this.setState({ showHideNotify: !this.state.showHideNotify });
  }
  handleModalShowHideSchedule() {
    this.setState({ showHideSchedule: !this.state.showHideSchedule });
  }

  componentDidMount() {
    if (!flagApi) {
      this.loadData();
      setTimeout(() => {
        $(document).on("click", ".submitBTN", () => {
          this.setState({ showHide: !this.state.showHide });
        });

        $(document).on("click", "#modalNotifyMe", () => {
          this.setState({ showHideNotify: !this.state.showHideNotify });
        });

        $(document).on("click", "#modalSchedule", () => {
          this.setState({ showHideSchedule: !this.state.showHideSchedule });
        });

        var refreshContentSc = document.createElement("script");
        $(refreshContentSc).text(
          "localStorage.setItem('startDate_sas_97466', 'start'); localStorage.setItem('temp_sas_97466', 'sas_97466'); localStorage.setItem('tempcount_sas_97466', 0);   setInterval(function(){ if(!$('#sas_97466').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97466').offset().top; var bottom_of_element = $('#sas_97466').offset().top + $('#sas_97466').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97466');  if(lastsyncdata=='start'){ console.log(lastsyncdata); localStorage.setItem('startDate_sas_97466', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97466')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97466'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97466'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97466');  localStorage.setItem('tempcount_sas_97466', (Number(localStorage.getItem('tempcount_sas_97466'))+1)); localStorage.setItem('startDate_sas_97466', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc);

        var refreshContentSc1 = document.createElement("script");
        $(refreshContentSc1).text(
          "localStorage.setItem('startDate_sas_97469', 'start'); localStorage.setItem('temp_sas_97469', 'sas_97469'); localStorage.setItem('tempcount_sas_97469', 0);   setInterval(function(){ if(!$('#sas_97469').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97469').offset().top; var bottom_of_element = $('#sas_97469').offset().top + $('#sas_97469').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97469'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97469', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97469')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97469'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97469'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97469'); localStorage.setItem('tempcount_sas_97469', (Number(localStorage.getItem('tempcount_sas_97469'))+1)); localStorage.setItem('startDate_sas_97469', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc1);

        var refreshContentSc2 = document.createElement("script");
        $(refreshContentSc2).text(
          "localStorage.setItem('startDate_sas_97587', 'start'); localStorage.setItem('temp_sas_97587', 'sas_97587'); localStorage.setItem('tempcount_sas_97587', 0);   setInterval(function(){ if(!$('#sas_97587').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97587').offset().top; var bottom_of_element = $('#sas_97587').offset().top + $('#sas_97587').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97587'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97587', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97587')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97587'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97587'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97587');  localStorage.setItem('tempcount_sas_97587', (Number(localStorage.getItem('tempcount_sas_97587'))+1)); localStorage.setItem('startDate_sas_97587', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc2);

        var refreshContentSc3 = document.createElement("script");
        $(refreshContentSc3).text(
          "localStorage.setItem('startDate_sas_97588', 'start'); localStorage.setItem('temp_sas_97588', 'sas_97588'); localStorage.setItem('tempcount_sas_97588', 0);   setInterval(function(){ if(!$('#sas_97588').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97588').offset().top; var bottom_of_element = $('#sas_97588').offset().top + $('#sas_97588').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97588'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97588', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97588')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97588'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97588'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97588'); localStorage.setItem('tempcount_sas_97588', (Number(localStorage.getItem('tempcount_sas_97588'))+1)); localStorage.setItem('startDate_sas_97588', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc3);

        var refreshContentSc4 = document.createElement("script");
        $(refreshContentSc4).text(
          "localStorage.setItem('startDate_sas_97721', 'start'); localStorage.setItem('temp_sas_97721', 'sas_97721'); localStorage.setItem('tempcount_sas_97721', 0);   setInterval(function(){ if(!$('#sas_97721').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97721').offset().top; var bottom_of_element = $('#sas_97721').offset().top + $('#sas_97721').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97721'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97721', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97721')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97721'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97721'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97721');  localStorage.setItem('tempcount_sas_97721', (Number(localStorage.getItem('tempcount_sas_97721'))+1)); localStorage.setItem('startDate_sas_97721', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc4);
        var refreshContentSc5 = document.createElement("script");
        $(refreshContentSc5).text(
          "localStorage.setItem('startDate_sas_97722', 'start'); localStorage.setItem('temp_sas_97722', 'sas_97722'); localStorage.setItem('tempcount_sas_97722', 0);   setInterval(function(){ if(!$('#sas_97722').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97722').offset().top; var bottom_of_element = $('#sas_97722').offset().top + $('#sas_97722').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97722'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97722', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97722')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97722'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97722'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97722');  localStorage.setItem('tempcount_sas_97722', (Number(localStorage.getItem('tempcount_sas_97722'))+1)); localStorage.setItem('startDate_sas_97722', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc5);

        var refreshContentSc6 = document.createElement("script");
        $(refreshContentSc6).text(
          "localStorage.setItem('startDate_sas_97723', 'start'); localStorage.setItem('temp_sas_97723', 'sas_97723'); localStorage.setItem('tempcount_sas_97723', 0);   setInterval(function(){ if(!$('#sas_97723').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97723').offset().top; var bottom_of_element = $('#sas_97723').offset().top + $('#sas_97723').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97723'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97723', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97723')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97723'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97723'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97723');  localStorage.setItem('tempcount_sas_97723', (Number(localStorage.getItem('tempcount_sas_97723'))+1)); localStorage.setItem('startDate_sas_97723', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc6);

        var refreshContentSc7 = document.createElement("script");
        $(refreshContentSc7).text(
          "localStorage.setItem('startDate_sas_974692', 'start'); localStorage.setItem('temp_sas_974692', 'sas_974692'); localStorage.setItem('tempcount_sas_974692', 0);   setInterval(function(){ if(!$('#sas_974692').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_974692').offset().top; var bottom_of_element = $('#sas_974692').offset().top + $('#sas_974692').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_974692'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_974692', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_974692')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_974692'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_974692'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_974692');  localStorage.setItem('tempcount_sas_974692', (Number(localStorage.getItem('tempcount_sas_974692'))+1)); localStorage.setItem('startDate_sas_974692', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc7);

        var refreshContentSc8 = document.createElement("script");
        $(refreshContentSc8).text(
          "localStorage.setItem('startDate_sas_97587_2', 'start'); localStorage.setItem('temp_sas_97587_2', 'sas_97587_2'); localStorage.setItem('tempcount_sas_97587_2', 0);   setInterval(function(){ if(!$('#sas_97587_2').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97587_2').offset().top; var bottom_of_element = $('#sas_97587_2').offset().top + $('#sas_97587_2').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97587_2'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97587_2', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97587_2')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97587_2'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97587_2'); if(loopCount<5 && Diffseconds>30){  sas.refresh('sas_97587_2');  localStorage.setItem('tempcount_sas_97587_2', (Number(localStorage.getItem('tempcount_sas_97587_2'))+1)); localStorage.setItem('startDate_sas_97587_2', new Date()); }}},1000)"
        );
        $("#CustomAdsRefreshscript").append(refreshContentSc8);
      }, 5000);

      //Listeners for window resize
      this.updateWindowDimensions();
      // window.addEventListener("resize", this.updateWindowDimensions);
      flagApi = true;
    }
  }

  componentWillUnmount() {
    flagApi = false;
    flagApi2 = false;
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.match.params.slug !== this.props.params.slug) {
  //     this.setState({ Loading: true });
  //     this.loadData();
  //     document.body.scrollTop = 0;
  //     document.documentElement.scrollTop = 0;
  //     window.location.href = `./digital-series/${this.props.params.slug}`;
  //   }
  // }
  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateWindowDimensions);
  //   window.location.reload();
  // }
  updateWindowDimensions() {
    if (this.props.params.slug !== "weallgrowsummit") {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  }
  loadData = async () => {
    this.setState({ Loading: true });
    var pageslug = this.props.params.slug
      ? this.props.params.slug
      : this.props.slug;
    await axiosstaticpages
      .get(`/pages/?slug=${pageslug}&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (pageslug === "hispanic-heritage-month") {
          const articlesSlugArray = ["hispanic-heritage-month"];
          const needContent = articlesSlugArray.includes(pageslug);

          this.setState({
            title: res.data[0].title.rendered,
            content: res.data[0].content.rendered,
            Loading: false,
            LoadingArticles: needContent,
            hasStorytellers: true,
            LoadingStorytellers: needContent,
            mainPostCategory: pageslug,
            articleAuthor: res.data[0].author,
          });

          if (needContent) {
            this.loadArticles(pageslug);
            this.loadCreators("storytellers");
          }

          // this.setState({ title: res.data[0].title.rendered,
          //                 content: res.data[0].content.rendered,
          //                 Loading: false,
          // });
        } else if (pageslug === "livingyready") {
          const articlesSlugArray = ["livingyready"];
          const needContent = articlesSlugArray.includes(pageslug);

          this.setState({
            title: res.data[0].title.rendered,
            content: res.data[0].content.rendered,
            Loading: false,
            LoadingArticles: needContent,
            LoadingGuests: needContent,
            hasGuests: true,
            LoadingExperts: needContent,
            hasExperts: true,
            mainPostCategory: pageslug,
            articleAuthor: res.data[0].author,
          });

          if (needContent) {
            this.loadArticles(pageslug);
            this.loadCreators("guest");
            this.loadCreators("expert");
          }

          // this.setState({ title: res.data[0].title.rendered,
          //                 content: res.data[0].content.rendered,
          //                 Loading: false,
          // });
        } else if (pageslug === "latino-alternative-storytellers") {
          const newPageSlug = "storytellers";
          const articlesSlugArray = ["storytellers"];
          const needContent = articlesSlugArray.includes(newPageSlug);

          this.setState({
            title: res.data[0].title.rendered,
            content: res.data[0].content.rendered,
            Loading: false,
            LoadingArticles: needContent,
            hasStorytellers: true,
            LoadingStorytellers: needContent,
            mainPostCategory: pageslug,
            articleAuthor: res.data[0].author,
          });

          if (needContent) {
            this.loadArticles(newPageSlug);
            this.loadCreators("storytellers2");
          }

          // this.setState({ title: res.data[0].title.rendered,
          //                 content: res.data[0].content.rendered,
          //                 Loading: false,
          // });
        } else if (pageslug === "stop-the-disinformation") {
          const newPageSlug = "stop-the-dis";
          const articlesSlugArray = ["stop-the-dis"];
          const needContent = articlesSlugArray.includes(newPageSlug);

          this.setState({
            title: res.data[0].title.rendered,
            content: res.data[0].content.rendered,
            Loading: false,
            LoadingArticles: needContent,
            LoadingGuests: needContent,
            hasGuests: true,
            LoadingExperts: needContent,
            hasExperts: true,
            mainPostCategory: pageslug,
            articleAuthor: res.data[0].author,
          });

          if (needContent) {
            this.loadArticles(newPageSlug);
            this.loadCreators("stop-the-dis");
          }
        } else if (pageslug === "vamostraveling") {
          const newPageSlug = "vamostraveling";
          const articlesSlugArray = ["vamostraveling"];
          const needContent = articlesSlugArray.includes(newPageSlug);

          this.setState({
            title: res.data[0].title.rendered,
            content: res.data[0].content.rendered,
            Loading: false,
            LoadingArticles: needContent,
            mainPostCategory: pageslug,
            articleAuthor: res.data[0].author,
          });

          if (needContent) {
            this.loadArticles(newPageSlug);
          }
        } else {
          this.setState({
            title: res.data[0].title.rendered,
            content: res.data[0].content.rendered,
            Loading: false,
            mainPostCategory: pageslug,
            articleAuthor: res.data[0].author,
          });
        }

        this.StructuredDataBreadcrumbList(pageslug);

        axios
          .get(
            `/get-page-by-slug/?slug=${pageslug}&timestamp=${new Date().getTime()}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
              },
            }
          )
          .then((res2) => {
            this.setState({
              cssfile: res2.data.data.cssfile,
              Loadingcss: false,
            });
          });

        // const scheduleSlug = "weallgrowsummitschedule";
        // axiosstaticpages.get(`/pages/?slug=${scheduleSlug}&timestamp=${new Date().getTime()}`, {
        //   headers: {
        //     "Accept": "application/json",
        //     "Content-Type": "application/json;charset=UTF-8",
        //   }
        // })
        // .then(resSchedule => {
        //   const sContent = resSchedule.data.length > 0 ? resSchedule.data[0].content.rendered : '';
        //   this.setState({ scheduleContent: sContent });
        // })
        // this.appendLotameScript();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    $("#lotameTag").append(scriptLotameTag);
  }

  lotameLoad() {
    let article_tags = [];
    let article_cats = [];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.title}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }

  loadArticles = async (cat) => {
    if (cat === "livingyready") {
      //la respuesta sin el items count -1 trae solo 9 articulos para paginar, seria cuestion de que cuando sean visualizados, hacer el nuevo request de la siguiente página
      await axios
        .get(
          `/get-post-by-category/?category=${cat}&itemscount=-1&timestamp=${new Date().getTime()}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          // const strRes = JSON.stringify(res.data.data)
          this.setState({
            contentArticles: res.data.data,
            LoadingArticles: false,
            listScript: StructuredDataListScript(
              res.data.data,
              cat,
              "Articles Section",
              `Articles Section from ${cat}`
            ),
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ contentArticles: [], LoadingArticles: false });
        });
    } else if (
      cat === "hispanic-heritage-month" ||
      cat === "storytellers" ||
      cat === "stop-the-dis"
    ) {
      //la respuesta sin el items count -1 trae solo 9 articulos para paginar, seria cuestion de que cuando sean visualizados, hacer el nuevo request de la siguiente página
      await axios
        .get(
          `/get-posts-by-tag/?tag=${cat}&itemscount=-1&timestamp=${new Date().getTime()}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          this.setState({
            contentArticles: res.data.data,
            LoadingArticles: false,
            listScript: StructuredDataListScript(
              res.data.data,
              cat,
              "Articles Section",
              `Articles Section from ${cat}`
            ),
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ contentArticles: [], LoadingArticles: false });
        });
    } else {
      await axios
        .get(
          `/get-post-by-category/?category=${cat}&timestamp=${new Date().getTime()}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          // const strRes = JSON.stringify(res.data.data)
          this.setState({
            contentArticles: res.data.data,
            LoadingArticles: false,
            listScript: StructuredDataListScript(
              res.data.data,
              cat,
              "Articles Section",
              `Articles Section from ${cat}`
            ),
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ contentArticles: [], LoadingArticles: false });
        });
    }
  };

  loadCreators = async (tag) => {
    await axios
      .get(`/get-creators-by-tag/?tag=${tag}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        // const strRes = JSON.stringify(res.data.data)
        if (tag === "guest") {
          this.setState({
            contentGuests: res.data.data,
            LoadingGuests: false,
            listScriptG: StructuredDataListScript(
              res.data.data,
              this.state.mainPostCategory,
              "Guests Section",
              `Guests Section from ${this.state.mainPostCategory}`
            ),
          });
        } else if (tag === "expert") {
          this.setState({
            contentExperts: res.data.data,
            LoadingExperts: false,
            listScriptE: StructuredDataListScript(
              res.data.data,
              this.state.mainPostCategory,
              "Experts Section",
              `Experts Section from ${this.state.mainPostCategory}`
            ),
          });
        } else if (tag === "storytellers") {
          this.setState({
            contentStorytellers: res.data.data,
            LoadingStorytellers: false,
            listScriptS: StructuredDataListScript(
              res.data.data,
              this.state.mainPostCategory,
              "Storytellers Section",
              `Storytellers Section from ${this.state.mainPostCategory}`
            ),
          });
        } else if (tag === "storytellers2") {
          this.setState({
            contentStorytellers: res.data.data,
            LoadingStorytellers: false,
            listScriptS: StructuredDataListScript(
              res.data.data,
              this.state.mainPostCategory,
              "Storytellers Section",
              `Storytellers Section from ${this.state.mainPostCategory}`
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (tag === "guest") {
          this.setState({ contentGuests: [], LoadingGuests: false });
        } else if (tag === "expert") {
          this.setState({ contentExperts: [], LoadingExperts: false });
        } else if (tag === "storytellers") {
          this.setState({
            contentStorytellers: [],
            LoadingStorytellers: false,
          });
        } else if (tag === "storytellers2") {
          this.setState({
            contentStorytellers: [],
            LoadingStorytellers: false,
          });
        }
      });
  };

  handleValidation() {
    let cname = this.state.cname;
    let email = this.state.email;
    let phone = this.state.phone;
    let message = this.state.message;

    let formIsValid = true;

    //Name
    if (!cname || typeof cname === "undefined") {
      formIsValid = false;
      this.setState({
        nameError: "Name should not be empty.",
      });
    } else if (typeof cname !== "undefined" && cname !== "") {
      var pattern = new RegExp(/^(?:[a-zA-Z][a-zA-Z\s]*)?$/);

      if (!pattern.test(cname)) {
        formIsValid = false;
        this.setState({
          nameError: "Name should not contain any special chars.",
        });
      }
    }
    //Message
    if (!message || typeof message === "undefined") {
      formIsValid = false;
      this.setState({
        messageError: "Message should not be empty.",
      });
    }

    //Phone
    if (!phone || typeof phone === "undefined") {
      formIsValid = false;
      this.setState({
        phoneError: "Phone No. should not be empty.",
      });
    } else if (typeof phone !== "undefined") {
      if (!phone.match(/^\d+$/)) {
        formIsValid = false;
        this.setState({
          phoneError: "Phone No. should be only in numbers.",
        });
      }
    } else if (phone.length < 9 || phone.length > 15) {
      formIsValid = false;
      this.setState({
        phoneError: "Invalid Phone Number",
      });
    }

    //Email
    if (!email) {
      formIsValid = false;
      this.setState({
        emailError: "Email address should not be empty.",
      });
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        formIsValid = false;
        this.setState({
          emailError: "Invalid Email address",
        });
      } else if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        this.setState({
          emailError: "Invalid Email address",
        });
      }
    }
    return formIsValid;
  }

  submitFile = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.handleValidation()) {
      const contactinfo = {
        name: this.state.cname,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
      };

      axios
        .post(`/send-mail`, contactinfo)
        .then((res) => {
          // if (res.data.code===1){
          this.setState({
            //   alertclass : res.data.messagetype,
            //   alerttype : 'Success! ',
            //   alertmessage : res.data.message,
            cname: "",
            email: "",
            phone: "",
            message: "",
          });
          // $('#wpforms-form-contact').trigger("reset");
          // $('#wpforms-form-contact').reset();
          // }
          // if (res.data.code===2){
          //   this.setState({
          //     alertclass : res.data.messagetype,
          //     alerttype : 'Error! ',
          //     alertmessage : res.data.message
          //   })
          // }
          this.handleModalShowHide();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  handleNameChange = (event) => {
    var cname = event.target.value;

    if (!cname || typeof cname === "undefined") {
      this.setState({
        nameError: "Name should not be empty.",
      });
    } else if (typeof cname !== "undefined" && cname !== "") {
      var pattern = new RegExp(/^(?:[a-zA-Z][a-zA-Z\s]*)?$/);

      if (!pattern.test(cname)) {
        this.setState({
          nameError: "Name should not contain any special chars.",
        });
      } else {
        this.setState({ nameError: null });
        this.setState({ cname: event.target.value });
      }
    }
  };
  handleEmailChange = (event) => {
    var email = event.target.value;
    //Email
    if (!email) {
      this.setState({
        emailError: "Email address should not be empty.",
      });
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        this.setState({
          emailError: "Invalid Email address",
        });
      } else if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        this.setState({
          emailError: "Invalid Email address",
        });
      } else {
        this.setState({ emailError: null });
        this.setState({ email: event.target.value });
      }
    }
  };
  handlePhoneChange = (event) => {
    //Phone
    var phone = event.target.value;

    if (!phone || typeof phone === "undefined") {
      this.setState({
        phoneError: "Phone No. should not be empty",
      });
    } else if (typeof phone !== "undefined") {
      if (!phone.match(/^\d+$/)) {
        this.setState({
          phoneError: "Phone No. should be only in number",
        });
      } else if (phone.length < 9 || phone.length > 15) {
        this.setState({
          phoneError: "Invalid Phone Number",
        });
      } else {
        this.setState({ phoneError: null });
        this.setState({ phone: event.target.value });
      }
    }
  };
  handleMessageChange = (event) => {
    var message = event.target.value;

    if (!message || typeof message === "undefined") {
      this.setState({
        messageError: "File URL Should not be empty.",
      });
    } else {
      this.setState({ messageError: null });
      this.setState({ message: event.target.value });
    }
  };

  getArticle(article) {
    var Link;
    if (article.post_type === "watch") {
      Link = `/watch/${article.post_slug}/`;
    } else {
      Link = `/${article.post_slug}/`;
    }
    return (
      <div style={{ padding: 8 }}>
        <div class="article-element">
          <a href={Link}>
            <p
              class="helvFont article-title"
              dangerouslySetInnerHTML={{ __html: article.post_title }}
            ></p>
            <img
              srcSet={article.imgs.toReversed().map((image, index) => {
                if (image[1] === undefined) {
                  return [];
                }
                const Srcset = [];
                const Zize = image[1].toString();
                const link = image[0].toString();
                Srcset.push(link + " " + Zize + "w");
                return Srcset;
              })}
              style={{ width: "100%" }}
              height="auto"
              src={article.post_image.medium_image_url}
              alt={article.post_image.image_title}
              className="img-sizing"
            />
            <p class="helvFont article-content">{article.short_description}</p>
          </a>
        </div>
      </div>
    );
  }

  getCreator(creator, type) {
    return (
      <div style={{ padding: 8 }}>
        <div class="creator-element">
          <a href={"/creator/" + type + "/" + creator.post_slug + "/"}>
            <p class="creator-image-container">
              <img
                style={{ width: "100%" }}
                height="auto"
                src={creator.post_image.medium_image_url}
                alt={creator.post_image.image_title}
                className="img-sizing"
              />
            </p>
            <p class="helvFont creator-title">{creator.post_title}</p>
          </a>
        </div>
      </div>
    );
  }

  getStoryteller(storyteller, type) {
    return (
      <div style={{ padding: 8 }}>
        <div class="creator-element">
          <a href={"/creator/" + type + "/" + storyteller.post_slug + "/"}>
            <p class="creator-image-container">
              <img
                style={{ width: "100%" }}
                height="auto"
                src={storyteller.post_image.medium_image_url}
                alt={storyteller.post_image.image_title}
                className="img-sizing"
              />
            </p>
            <p class="helvFont creator-title">{storyteller.post_title}</p>
          </a>
        </div>
      </div>
    );
  }

  StructuredDataBreadcrumbList = (pageSlug) => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://latv.com/",
            name: "Home",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `https://latv.com/series/`,
            name: "series",
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `https://latv.com/series/${pageSlug}/`,
            name: pageSlug,
          },
        },
      ],
    };
    this.setState({
      breadcrumbsListScript: structuredData,
    });
  };

  render() {
    const {
      Loading,
      Loadingcss,
      LoadingArticles,
      LoadingGuests,
      hasGuests,
      hasExperts,
      hasStorytellers,
      LoadingExperts,
      LoadingStorytellers,
      MailChimpUrl,
      content,
      contentArticles,
      contentGuests,
      contentExperts,
      contentStorytellers,
      cssfile,
      nameError,
      emailError,
      phoneError,
      messageError,
      width,
      scheduleContent,
      breadcrumbsListScript,
      listScript,
      listScriptG,
      listScriptE,
      listScriptS,
    } = this.state;
    const getArticle = this.getArticle;
    const getCreator = this.getCreator;
    const getStoryteller = this.getStoryteller;
    const pageslug = this.state.mainPostCategory;
    if (
      !Loading &&
      !Loadingcss &&
      !LoadingArticles &&
      !LoadingGuests &&
      !LoadingExperts &&
      !LoadingStorytellers
    ) {
      setTimeout(function () {
        var div = document.createElement("span");
        div.innerHTML = content;
        if (Loadingcss !== true) {
          $(document).ready(function () {
            $(
              `<link rel="preload" href="${
                mediastaticpages.mediaUrl
              }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}" as="style" onload="this.onload=null;this.rel='stylesheet'">`
            ).appendTo("head");
            $(
              `<noscript><link rel="stylesheet" href="${
                mediastaticpages.mediaUrl
              }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}"></noscript>`
            ).appendTo("head");
          });
        }

        if (Loading !== true) {
          if (content !== "" && content !== null && content !== "undefined") {
            $("#content-rendered-digital-series").html("");
            $("#content-rendered-digital-series").append(content);
            $("#posts_section").addClass("posts_section");
            var clips_section = document.getElementById("clips_section");
            var episodes_section = document.getElementById("episodes_section");
            var articles_section = document.getElementById("articles_section");
            var special_articles_section = document.getElementById(
              "special_articles_section"
            );
            var contextly_articles_section = document.getElementById(
              "contextly_articles_section"
            );
            var related_articles_section = document.getElementById(
              "related_articles_section"
            );
            var pageslugVar = pageslug;
            // console.log(contextly_articles_section);
            // console.log(special_articles_section);
            var posts_section = document.getElementById("posts_section");
            var series_slider_section = document.getElementById(
              "series_slider_section"
            );
            var header_part = document.getElementById("header_part");
            if (typeof header_part != "undefined" && header_part != null) {
              var clone = $("#header_part").parent().clone();
              $("#header_part").remove();
              clone.appendTo("#header_part_render");
            }
            var category;
            var title;
            var pagination;
            var itemscount;
            var post_tag;
            var operator;
            // Falta clips load
            // if (typeof clips_section != "undefined" && clips_section != null) {
            //   var category = document
            //     .getElementById("clips_section")
            //     .getAttribute("data-category");
            //   var title = document
            //     .getElementById("clips_section")
            //     .getAttribute("data-title");
            //   var pagination = document
            //     .getElementById("clips_section")
            //     .getAttribute("data-pagination");
            //   var itemscount = document
            //     .getElementById("clips_section")
            //     .getAttribute("data-itemscount");
            //   var post_tag = document
            //     .getElementById("clips_section")
            //     .getAttribute("data-post_tag");
            //   ReactDOM.render(
            //     <ClipsLoadComponent
            //       category={category}
            //       title={title}
            //       pagination={pagination}
            //       itemscount={itemscount}
            //       post_tag={post_tag}
            //     />,
            //     document.getElementById("clips_section")
            //   );
            // }
            // if (
            //   typeof episodes_section != "undefined" &&
            //   episodes_section != null
            // ) {
            //   category = document
            //     .getElementById("episodes_section")
            //     .getAttribute("data-category");
            //   title = document
            //     .getElementById("episodes_section")
            //     .getAttribute("data-title");
            //   pagination = document
            //     .getElementById("episodes_section")
            //     .getAttribute("data-pagination");
            //   itemscount = document
            //     .getElementById("episodes_section")
            //     .getAttribute("data-itemscount");
            //   post_tag = document
            //     .getElementById("episodes_section")
            //     .getAttribute("data-post_tag");
            //   ReactDOM.render(
            //     <EpisodesLoadComponent
            //       category={category}
            //       title={title}
            //       pagination={pagination}
            //       itemscount={itemscount}
            //       post_tag={post_tag}
            //     />,
            //     document.getElementById("episodes_section")
            //   );
            // }
            if (
              typeof articles_section != "undefined" &&
              articles_section != null
            ) {
              category = document
                .getElementById("articles_section")
                .getAttribute("data-category");
              title = document
                .getElementById("articles_section")
                .getAttribute("data-title");
              pagination = document
                .getElementById("articles_section")
                .getAttribute("data-pagination");
              itemscount = document
                .getElementById("articles_section")
                .getAttribute("data-itemscount");
              post_tag = document
                .getElementById("articles_section")
                .getAttribute("data-post_tag");
              ReactDOM.render(
                <ArticlesLoadComponent
                  category={category}
                  title={title}
                  pagination={pagination}
                  itemscount={itemscount}
                  post_tag={post_tag}
                />,
                document.getElementById("articles_section")
              );
            }
            // if (
            //   typeof contextly_articles_section != "undefined" &&
            //   contextly_articles_section != null
            // ) {
            //   category = document
            //     .getElementById("contextly_articles_section")
            //     .getAttribute("data-category");
            //   title = document
            //     .getElementById("contextly_articles_section")
            //     .getAttribute("data-title");
            //   pagination = document
            //     .getElementById("contextly_articles_section")
            //     .getAttribute("data-pagination");
            //   itemscount = document
            //     .getElementById("contextly_articles_section")
            //     .getAttribute("data-itemscount");
            //   post_tag = document
            //     .getElementById("contextly_articles_section")
            //     .getAttribute("data-post_tag");
            //   var operator = document
            //     .getElementById("contextly_articles_section")
            //     .getAttribute("data-operator");
            //   ReactDOM.render(
            //     <SpecialArticle404 title={title} itemscount={itemscount} />,
            //     //   <SpecialsArticlesLoadComponent
            //     //   category={category}
            //     //   title={title}
            //     //   itemscount={itemscount}
            //     //   post_tag={post_tag}
            //     //   operator={operator}
            //     // />,
            //     document.getElementById("contextly_articles_section")
            //   );
            // }
            if (
              typeof special_articles_section != "undefined" &&
              special_articles_section != null
            ) {
              category = document
                .getElementById("special_articles_section")
                .getAttribute("data-category");
              title = document
                .getElementById("special_articles_section")
                .getAttribute("data-title");
              pagination = document
                .getElementById("special_articles_section")
                .getAttribute("data-pagination");
              itemscount = document
                .getElementById("special_articles_section")
                .getAttribute("data-itemscount");
              post_tag = document
                .getElementById("special_articles_section")
                .getAttribute("data-post_tag");
              operator = document
                .getElementById("special_articles_section")
                .getAttribute("data-operator");
              var excerpt = document
                .getElementById("special_articles_section")
                .getAttribute("excerpt");
              ReactDOM.render(
                <SpecialsArticlesLoadComponent
                  category={category}
                  title={title}
                  itemscount={itemscount}
                  post_tag={post_tag}
                  operator={operator}
                  excerpt={excerpt}
                />,
                document.getElementById("special_articles_section")
              );
            }
            if (
              typeof related_articles_section != "undefined" &&
              related_articles_section != null
            ) {
              category = document
                .getElementById("related_articles_section")
                .getAttribute("data-category");
              title = document
                .getElementById("related_articles_section")
                .getAttribute("data-title");
              pagination = document
                .getElementById("related_articles_section")
                .getAttribute("data-pagination");
              itemscount = document
                .getElementById("related_articles_section")
                .getAttribute("data-itemscount");
              post_tag = document
                .getElementById("related_articles_section")
                .getAttribute("data-post_tag");
              operator = document
                .getElementById("related_articles_section")
                .getAttribute("data-operator");
              ReactDOM.render(
                <ContextlyLoadComponent
                  category={category}
                  title={title}
                  pagination={pagination}
                  itemscount={itemscount}
                />,
                document.getElementById("related_articles_section")
              );
              // ReactDOM.render(<PostsLoadComponent category={category} title={title} pagination={pagination} itemscount={itemscount}/>, document.getElementById('posts_section'));
            }
            // if (typeof posts_section != "undefined" && posts_section != null) {
            //   $(".posts_section").each((i, el) => {
            //     category = document
            //       .getElementsByClassName("posts_section")
            //       [i].getAttribute("data-category");
            //     title = document
            //       .getElementsByClassName("posts_section")
            //       [i].getAttribute("data-title");
            //     pagination = document
            //       .getElementsByClassName("posts_section")
            //       [i].getAttribute("data-pagination");
            //     itemscount = document
            //       .getElementsByClassName("posts_section")
            //       [i].getAttribute("data-itemscount");
            //     ReactDOM.render(
            //       <PostsLoadComponent
            //         category={category}
            //         title={title}
            //         pagination={pagination}
            //         itemscount={itemscount}
            //       />,
            //       document.getElementsByClassName("posts_section")[i]
            //     );
            //   });
            //   // ReactDOM.render(<PostsLoadComponent category={category} title={title} pagination={pagination} itemscount={itemscount}/>, document.getElementById('posts_section'));
            // }
            // if (
            //   typeof series_slider_section != "undefined" &&
            //   series_slider_section != null
            // ) {
            //   ReactDOM.render(
            //     <Gallery4x4Component />,
            //     document.getElementById("series_slider_section")
            //   );
            // }
            $("#app-download").remove();
            $(".remove-placeholder").remove();

            let needGetContent = "";
            // console.log(pageslug);
            if (pageslug === "livingyready") {
              const articlesSlugArray = ["livingyready"];
              needGetContent = articlesSlugArray.includes(pageslug);
            } else if (pageslug === "hispanic-heritage-month") {
              const articlesSlugArray = ["hispanic-heritage-month"];
              needGetContent = articlesSlugArray.includes(pageslug);
            } else if (pageslug === "latino-alternative-storytellers") {
              const newPageSlug = "storytellers";
              const articlesSlugArray = ["storytellers"];
              needGetContent = articlesSlugArray.includes(newPageSlug);
            } else if (pageslug === "stop-the-disinformation") {
              const newPageSlug = "storytellers";
              const articlesSlugArray = ["storytellers"];
              needGetContent = articlesSlugArray.includes(newPageSlug);
            } else if (pageslug === "vamostraveling") {
              const articlesSlugArray = ["vamostraveling"];
              needGetContent = articlesSlugArray.includes(pageslug);
            }
            // console.log(needGetContent);

            let carouselShow = 3; //Desktop
            if (width < 600) {
              carouselShow = 1;
            } //Mobile
            if (width >= 600 && width < 1025) {
              carouselShow = 2;
            } //Tablet

            //Articles
            if (needGetContent && LoadingArticles !== true) {
              if (pageslug === "livingyready") {
                $("#lyrArticles").html("");
                let articlesCarusel = [];
                contentArticles.forEach((article) => {
                  articlesCarusel.push(getArticle(article));
                });
                ReactDOM.render(
                  <Carousel
                    show={carouselShow}
                    infiniteLoop={false}
                    children={articlesCarusel}
                  />,
                  document.getElementById("lyrArticles")
                );
              } else if (pageslug === "hispanic-heritage-month") {
                $("#hhmArticles").html("");
                let articlesCarusel = [];
                contentArticles.forEach((article) => {
                  articlesCarusel.push(getArticle(article));
                });
                ReactDOM.render(
                  <Carousel
                    show={carouselShow}
                    infiniteLoop={false}
                    children={articlesCarusel}
                  />,
                  document.getElementById("hhmArticles")
                );
              } else if (pageslug === "latino-alternative-storytellers") {
                $("#tellersArticles").html("");
                let articlesCarusel = [];
                contentArticles.forEach((article) => {
                  articlesCarusel.push(getArticle(article));
                });
                ReactDOM.render(
                  <Carousel
                    show={carouselShow}
                    infiniteLoop={false}
                    children={articlesCarusel}
                  />,
                  document.getElementById("tellersArticles")
                );
              } else if (pageslug === "stop-the-disinformation") {
                $("#stdArticles").html("");
                let articlesCarusel = [];
                contentArticles.forEach((article) => {
                  articlesCarusel.push(getArticle(article));
                });
                ReactDOM.render(
                  <Carousel
                    show={carouselShow}
                    infiniteLoop={false}
                    children={articlesCarusel}
                  />,
                  document.getElementById("stdArticles")
                );
              } else if (pageslug === "vamostraveling") {
                $("#vamostravelingArticles").html("");
                let articlesCarusel = [];
                contentArticles.forEach((article) => {
                  articlesCarusel.push(getArticle(article));
                });
                ReactDOM.render(
                  <Carousel
                    show={carouselShow}
                    infiniteLoop={false}
                    children={articlesCarusel}
                  />,
                  document.getElementById("vamostravelingArticles")
                );
              }
            }

            // //Articles for hispanic-heritage-month
            // if (needGetContent && LoadingArticles !== true) {
            //   console.log("entra en hispanic-heritage-month y renderiza los articulos")
            //   $("#hhmArticles").html("");
            //   let articlesCarusel = [];
            //   contentArticles.forEach((article) => {
            //     articlesCarusel.push(getArticle(article));
            //   });
            //   ReactDOM.render(
            //     <Carousel
            //       show={2}
            //       infiniteLoop={false}
            //       children={articlesCarusel}
            //     />,
            //     document.getElementById("hhmArticles")
            //   );
            // }

            // let carouselShow = 3; //Desktop
            // if (width < 600) { carouselShow = 1 } //Mobile
            // if (width >= 600 && width < 1025) { carouselShow = 2 } //Tablet

            //Guests
            if (needGetContent && LoadingGuests !== true && hasGuests) {
              $(".guestSlider").html("");
              let creatorCarusel = [];
              contentGuests.forEach((guest) => {
                creatorCarusel.push(getCreator(guest, "guest"));
              });
              ReactDOM.render(
                <Carousel
                  show={carouselShow}
                  infiniteLoop={false}
                  children={creatorCarusel}
                />,
                document.getElementById("guestSlider")
              );
            }

            //Storytellers
            if (
              needGetContent &&
              LoadingStorytellers !== true &&
              hasStorytellers
            ) {
              $("#storytellersSlider").html("");
              let StorytellerCarusel = [];
              contentStorytellers.forEach((storyteller) => {
                StorytellerCarusel.push(
                  getStoryteller(storyteller, "storyteller")
                );
              });
              ReactDOM.render(
                <Carousel
                  show={carouselShow}
                  infiniteLoop={false}
                  children={StorytellerCarusel}
                />,
                document.getElementById("storytellersSlider")
              );
            }

            //Experts
            if (needGetContent && LoadingExperts !== true && hasExperts) {
              $(".expertSlider").html("");
              let creatorCarusel = [];
              contentExperts.forEach((expert) => {
                creatorCarusel.push(getCreator(expert, "expert"));
              });
              ReactDOM.render(
                <Carousel
                  show={carouselShow}
                  infiniteLoop={false}
                  children={creatorCarusel}
                />,
                document.getElementById("expertSlider")
              );
            }
          } else {
            $("#content-rendered-digital-series").html("");
          }
        }

        flagApi2 = true;
      }, 100);
    } else {
      return (
        <>
          <section id="main" className="container-xl">
            <div className="row mt-5">
              <div className="col-md-6 col-12">
                <div className="row p-3">
                  <div
                    className="img-fluid image-placeholder placeholder img-sizing p-0"
                    alt=""
                  ></div>
                </div>
              </div>
              <div className="col-md-6 col-12 p-3">
                <div className="row mb-3">
                  <h1 className="title-placeholder placeholder"></h1>
                </div>
                <div className="row mb-2">
                  <div className="main-box-content text-placeholder placeholder"></div>
                </div>
                <div className="row">
                  <div className="main-box-content text-placeholder placeholder"></div>
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-5">
              <div className="row col-md-2 col-12"></div>
              <div className="row col-md-8 col-12 ">
                <div className="row mb-3">
                  <div
                    className="img-fluid image-placeholder placeholder img-sizing p-0"
                    alt=""
                  ></div>
                </div>
                <div className="row mb-3">
                  <h2 className="title-placeholder placeholder"></h2>
                </div>
                <div className="row mb-2">
                  <div className="main-box-content text-placeholder placeholder"></div>
                </div>
                <div className="row">
                  <div className="main-box-content text-placeholder placeholder"></div>
                </div>
              </div>
              <div className="row col-md-2 col-12"></div>
            </div>
          </section>
        </>
      );
    }

    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScriptE)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScriptG)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScriptS)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbsListScript)}
          </script>
        </Helmet>
        <section
          id="read-section digitalseries"
          className="read-section mt-0 mt-md-5"
        >
          <div className="vcv-content--blank vcv-content--boxed">
            <article
              id=""
              className="page type-page status-publish hentry entry"
            >
              <div
                className="entry-content"
                id="content-rendered-digital-series"
              >
                {/* <div id="content-rendered"> */}
                {/* dangerouslySetInnerHTML={{ __html: content }}> */}
                {/* </div> */}
              </div>
            </article>
          </div>

          <div id="CustomAdsRefreshscript"></div>
        </section>

        <Modal
          show={this.state.showHide}
          dialogClassName="my-modal"
          animation={false}
        >
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            <Modal.Title>Submit File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="wpforms-container wpforms-container-full"
              id="wpforms-504"
            >
              <form onSubmit={() => this.submitFile}>
                <div className="wpforms-field-container">
                  <div>
                    <label
                      className="wpforms-field-label wpforms-label-hide"
                      htmlFor="wpforms-504-field_0"
                    >
                      Name <span className="wpforms-required-label">*</span>{" "}
                      <span className="label-danger">{nameError}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      onChange={this.handleNameChange}
                      id="wpforms-504-field_0"
                      className="form-control"
                      name="wpforms[fields][0]"
                      placeholder="Name *"
                      required=""
                    />
                  </div>
                  <div>
                    <label
                      className="wpforms-field-label wpforms-label-hide"
                      htmlFor="wpforms-504-field_1"
                    >
                      Email <span className="wpforms-required-label">*</span>{" "}
                      <span className="label-danger">{emailError}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="email"
                      onChange={this.handleEmailChange}
                      id="wpforms-504-field_1"
                      className="form-control"
                      name="wpforms[fields][1]"
                      placeholder="Email *"
                      required=""
                    />
                  </div>
                  <div>
                    <label
                      className="wpforms-field-label wpforms-label-hide"
                      htmlFor="wpforms-504-field_3"
                    >
                      Phone <span className="wpforms-required-label">*</span>{" "}
                      <span className="label-danger">{phoneError}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="number"
                      onChange={this.handlePhoneChange}
                      id="wpforms-504-field_3"
                      className="form-control"
                      name="wpforms[fields][3]"
                      placeholder="Phone *"
                    />
                  </div>
                  <div>
                    <label
                      className="wpforms-field-label wpforms-label-hide"
                      htmlFor="wpforms-504-field_2"
                    >
                      File URL <span className="wpforms-required-label">*</span>{" "}
                      <span className="label-danger">{messageError}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.handleMessageChange}
                      placeholder="Drive, Dropbox, etc."
                      autoComplete="off"
                    />
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.submitFile()}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Modal
          show={this.state.showHideNotify}
          dialogClassName="my-modal"
          animation={false}
        >
          <Modal.Header
            closeButton
            onClick={() => this.handleModalShowHideNotify()}
          >
            <Modal.Title>Notify Me - #WeAllGrow Summit Livestream</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IFrameComponent
              source={"https://eepurl.com/h-xXin"}
              height={400}
            ></IFrameComponent>
          </Modal.Body>
        </Modal> */}

        <Modal
          show={this.state.showHideSchedule}
          dialogClassName="my-modal"
          animation={false}
        >
          <Modal.Header
            closeButton
            onClick={() => this.handleModalShowHideSchedule()}
          >
            <Modal.Title>Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body my-modal-schedule-body">
            {/* <IFrameComponent source={"https://staging.latv.com/other/weallgrowsummitschedule/"} height={400}></IFrameComponent> */}
            <div dangerouslySetInnerHTML={{ __html: scheduleContent }} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withParams(DigitalSeriesPageComponent);
