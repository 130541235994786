// import LoadingPost2 from '../Images/loading2.jpg';
import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import Img from "react-cool-img";
// import LoadingPost from "../Images/loading.jpg";
// import LazyLoad from 'react-lazyload';
// import video from '../Images/video-sm.jpeg';
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import { Helmet } from "react-helmet";
// import Sas97469Component from './Ads/Sas97469Component';
// import Sas97587Component from './Ads/Sas97587Component';
// import Sas97723Component from './Ads/Sas97723Component';
// import Sas974692Component from './Ads/Sas974692Component';
// import Sas97722Component from './Ads/Sas97722Component';
// import Sas97721Component from './Ads/Sas97721Component';
// import ClipPlaceholderComponent from "./Placeholders/ClipPlaceholderComponent";
// import { isMobileOnly } from "react-device-detect";
// import SideArticleComponent from "./Placeholders/SideArticleComponent";
// import "../CustomCss/watchPost.css";
import GridComponent2 from "../SharedComponents/GridComponent2";
let flagApi = false;
let flagApi2 = false;

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class SingleWatchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      LoadingAll: true,
      Posts: [],
      LatestPosts: [],
      LatestVideosPosts: [],
      LatestEpisodePosts: [],
      LatestVideosPostsCat: [],
      pageNumber: 1,
      searchSlug: "",
      status: "start",
      mainPostCategory: "",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
    };
  }
  do_load = () => {
    var script2 = document.createElement("script");
    script2.src =
      "https://cdn.jwplayer.com/players/acKr2ukJ-owsYDZVC.js?search=__CONTEXTUAL__";
    script2.defer = true;
    document.body.appendChild(script2);
  };

  // do_load_content = () => {
  //   var div = document.createElement('span');
  //   div.innerHTML = this.state.Posts.post_content;
  //         $('#content-rendered').append(div);
  // }

  componentDidMount() {
    if (!flagApi) {
      this.do_load();
      // const { match: { params } } = this.props;
      this.loadPosts();
      window.scrollTo(0, 0);
      // $(".tooltip").hide();
      // this.loadLatestPosts();
      flagApi = true;
    }
  }
  //   componentDidUpdate(prevProps) {
  //     if (prevProps.match.params.slug !== this.props.match.params.slug) {
  //       this.setState({ Loading: true });
  //       this.setState({ LoadingAll: true });
  //       this.loadPosts();

  //       document.body.scrollTop = 0;
  //       document.documentElement.scrollTop = 0;
  //       window.location.href = `./${this.props.match.params.slug}`;
  //     }
  //   }

  /* Lotame Stuff*/
  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    // console.log(document.getElementById('lotameTag'));
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    // console.log("lotame load")
    // let path_name = window.location.pathname
    // let url = window.location.href;
    // console.log(this.state.mainPostCategory)
    let article_tags = [];
    let article_cats = [];
    let int = [
      `author: ${this.state.articleAuthor}`,
      `main category: ${this.state.mainPostCategory}`,
    ];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    this.state.subCategories.forEach((s) => {
      int.push(s);
    });
    let data = {
      behaviors: {
        int: int,
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }
  /* Lotame Stuff*/

  //   componentWillUnmount() {
  //     window.location.reload();
  //   }
  loadPosts = async () => {
    // console.log("From Request", this.props.match.params.slug);
    await axios
      .get(
        `/get-video-post-by-slug?slug=${
          this.props.params.slug
        }&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          // window.location.href = `./other/${this.props.match.params.slug}`;
          //Change on production build
          // window.location.href = window.location.origin + `/${this.props.match.params.slug}`;
        }
        // if ( !!res.data.data.post_category.find((category) => category.cat_name === 'QUEER' ) ){
        //   window.location.href = `../queer/watch/${this.props.match.params.slug}`;
        // }
        let categories = [];
        res.data.data.post_category.forEach((element) => {
          categories.push(element.cat_slug);
        });
        this.setState({
          Posts: res.data.data,
          Loading: false,
          mainPostCategory: res.data.data.post_category[0]?.cat_slug,
          subCategories: categories,
          articleTags: res.data.data.post_tags,
          articleAuthor: res.data.data.author_name,
          articleTitle: res.data.data.post_title,
        });
        // console.log(res.data.data)
        this.loadLatestPosts();
        this.loadLatestVideosPosts();
        if (res.data.data.post_category.length !== 0) {
          this.LoadLatestVideoCategoryPosts(
            res.data.data.post_category[0].cat_slug
          );
        }
        if (res.data.data.showData) {
          this.LoadLatestVideoSeriesPosts(res.data.data.showData.showName);
        }
        this.appendLotameScript();
        //     setTimeout(function () {
        //       var content = `<script>
        //       var s1 = document.createElement("script"), el = document.getElementsByTagName("script")[0];
        // s1.defer = true;
        // s1.src = "https://ced.sascdn.com/tag/3928/smart.js";
        // el.parentNode.insertBefore(s1, el);
        // var ads = [];
        // ads.push({ id: 97466 },{ id: 97469 },{ id: 97587 },{ id: 97523 },{ id: 97522 },{ id: 97469_2 });
        // var pageid = 1345718;
        // var sas = sas || {};
        // sas.cmd = sas.cmd || [];
        // sas.cmd.push(function() {
        // sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async : true });
        //      });
        // sas.cmd.push(function () {
        // sas.enableLazyLoading({
        //     fetchRatio: 0.1,
        //     mobileFetchRatio: 0.1,
        //     formats: [97721,97722,97723,97466,97469,97587,97523,97522,97569_2,97587_2,97588,974692]
        //   });
        // });
        // sas.cmd.push(function() {
        //    sas.call("onecall", {
        //      siteId: 391880,
        //      pageId: pageid,
        //      formats: [
        //        ...ads
        //      ],
        //      target: 'wpid=${res.data.data.ID}|${res.data.data.post_tags.join(";")}'
        //    });
        // });
        // sas.cmd.push(function() { sas.render("97466"); });
        // sas.cmd.push(function() { sas.render("97469"); });
        // sas.cmd.push(function() { sas.render("97587"); });
        // sas.cmd.push(function() { sas.render("97523"); });
        // sas.cmd.push(function() { sas.render("97522"); });
        // sas.cmd.push(function() { sas.render("97569_2"); });
        //  localStorage.clear();
        //       </script>`;
        //       var div = document.createElement("span");
        //       div.innerHTML = content;

        //       $("#ads-rendered").html("");
        //       $("#ads-rendered").append(content);
        //     }, 100);
      })
      .catch((error) => {
        console.log(error);
      });
    // this.loadLatestPosts();
  };

  loadLatestPosts = async () => {
    // console.log("From Request", this.props.match.params.slug);
    await axios
      .get(`/get-home-page-posts?page_no=1&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data.code === 0) {
          // window.location.href = `./other/${this.props.match.params.slug}`;
          //Change on production build
          // window.location.href = window.location.origin + `/${this.props.match.params.slug}`;
        }
        this.setState({ LatestPosts: res.data.data });
        // this.setState({ Loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadLatestVideosPosts = async () => {
    // console.log("From Request", this.props.match.params.slug);
    await axios
      .get(
        `/get-home-page-video-posts?page_no=1&per_page=8&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.code === 0) {
          // window.location.href = `./other/${this.props.match.params.slug}`;
          //Change on production build
          // window.location.href = window.location.origin + `/${this.props.match.params.slug}`;
        }
        this.setState({ LatestVideosPosts: res.data.data });
        // console.log("Latest Videos Posts")
        // console.log(res.data.data)
        // this.setState({ Loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  LoadLatestVideoCategoryPosts = async (catSlug) => {
    await axios
      .get(`/get-post-by-category?category=${catSlug}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data.code === 0) {
          // window.location.href = `./other/${this.props.match.params.slug}`;
          //Change on production build
          // window.location.href = window.location.origin + `/${this.props.match.params.slug}`;
        }
        // console.log(this.props)
        // console.log(res.data)
        this.setState({ LatestVideosPostsCat: res.data.data });
        // console.log(this.LatestVideosPosts)
        // this.setState({ Loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  LoadLatestVideoSeriesPosts = async (showName) => {
    this.setState({ oadingLatestEpisondes: true });
    await axios
      .get(`/get-video-posts-by-show-slug?slug=${showName}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data.code === 0) {
          // window.location.href = `./other/${this.props.match.params.slug}`;
          //Change on production build
          // window.location.href = window.location.origin + `/${this.props.match.params.slug}`;
        }
        //  console.log(this.state.Posts.showData.showName)
        //  console.log(res.data)
        // console.log(res.data.data);
        this.setState({
          LatestEpisodePosts: res.data.data,
          loadingLatestEpisondes: false,
        });
        // console.log(this.LatestVideosPosts)
        // this.setState({ Loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let Bsize = "";
    const {
      Loading,
      loadingLatestEpisondes,
      Posts,
      LatestPosts,
      LatestVideosPosts,
      LatestEpisodePosts,
      LatestVideosPostsCat,
    } = this.state;
    let counter = 0;

    // var self = this;
    // setTimeout(function () {
    //   if (self.state.status === "start") {
    //     self.state.status = "loading";
    //     self.do_load();
    //     // self.do_load_content();
    //   }
    // }, 2000);

    if (!Loading && !loadingLatestEpisondes && !flagApi2) {
      setTimeout(function () {
        var div = document.createElement("span");
        div.innerHTML = Posts.post_content;
        if (
          Posts.post_title !== "" &&
          Posts.post_title !== null &&
          Posts.post_title !== "undefined"
        ) {
          $("#content-rendered").html("");
          $("#content-rendered").append(Posts.post_content);

          var MyDiv = document.getElementById("content-rendered");
          if (MyDiv !== null) {
            var arr = MyDiv.getElementsByTagName("script");
            // console.log(arr);
            for (var n = 0; n < arr.length; n++) {
              var filename = arr[n].src
                .substring(arr[n].src.lastIndexOf("/") + 1)
                .split(".");
              // console.log(filename)
              if (filename && filename !== undefined) {
                $("#content-rendered").html("");
                var replacedtag =
                  "botr_" + filename[0].replace("-", "_") + "_div";
                // console.log(replacedtag)
                $("#content-rendered").append(
                  "<div id=" + replacedtag + "></div>"
                );
                var script2 = document.createElement("script");
                script2.src = filename;
                script2.async = true;
                document.body.appendChild(script2);
                $("#content-rendered").append(Posts.post_content);
              }
            }
          }
        } else {
          $("#content-rendered").html("");
        }
      }, 2500);
      flagApi2 = true;
    }

    return (
      <>
        {!Loading ? (Posts ? `` : "No Post Found") : ``}
        {/* {!Loading ? (
          <Helmet>
            <title>{Posts.post_title}</title>
            <meta name="title" content={Posts.meta_title.substring(0, 65)} />
            <meta name="description" content={Posts.meta_description.substring(0, 150)} />
          </Helmet>
        ) : (``)} */}
        {/* <Sas97722Component/> */}
        {/* <Sas97723Component /> */}
        <section id="read-section" className="read-section">
          <div className="container">
            <div className="row">
              {!Loading ? (
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                  key={Posts.ID}
                >
                  <h1
                    className="super-heading"
                    data-toggle="tooltip"
                    title={Posts.post_title}
                    dangerouslySetInnerHTML={{
                      __html:
                        Posts.post_title !== null && Posts.post_title !== ""
                          ? Posts.post_title.length > 120
                            ? Posts.post_title.substr(0, 120) + `...`
                            : Posts.post_title
                          : ``,
                    }}
                  ></h1>

                  <div className="main-img-box">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div
                          className="main-box-content"
                          id="content-rendered"
                          style={{ minHeight: "50vh" }}
                        >
                          {/* <div className="main-box-content" id="content-rendered" dangerouslySetInnerHTML={{ __html: Posts.post_content }}> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="side-video-box">
                  <h4 className="side-heading mt-2 mb-2 uppercase">
                    {LatestEpisodePosts.length >= 1
                      ? `MORE FROM ` + Posts.showData.showName
                      : ``}
                  </h4>
                  <div className="row">
                    {Posts.showData ? (
                      <GridComponent2
                        addClass="side-scroll-network"
                        itemsCount={3}
                        state={this.state}
                        columns={1}
                        pagination={false}
                        excerpt={false}
                        loadType="read"
                        pageNumber="1"
                        Posts={LatestEpisodePosts}
                        sidescroll={false}
                        Loading={loadingLatestEpisondes}
                        clips={true}
                      />
                    ) : (
                      ``
                    )}
                  </div>

                  {!Posts.showData && !Loading ? (
                    <>
                      <h4 className="side-heading mt-2 mb-2 uppercase">
                        TRENDING VIDEO
                      </h4>
                      <div className="side-video-img">
                        <div id="botr_acKr2ukJ_owsYDZVC_div"></div>
                      </div>
                      {/* <Sas97587Component /> */}
                    </>
                  ) : (
                    ``
                  )}

                  {/* <Sas97469Component />
                  <Sas974692Component/> */}
                </div>
              </div>
              {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

    </div>   */}
            </div>
            <div className="row">
              {!Loading ? (
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                  key={Posts.ID}
                >
                  <div className="home_watch_clips">
                    <h4 className="side-heading mt-2 mb-2 uppercase">
                      {Posts.post_category.length !== 0 &&
                      Posts.post_category[0].cat_slug
                        ? `MORE FROM ` + Posts.post_category[0].cat_slug
                        : `WATCH MORE VIDEOS`}
                    </h4>
                    <div className="row">
                      {Posts.post_category.length === 0 ? (
                        <GridComponent2
                          addClass="side-scroll-network"
                          itemsCount={4}
                          state={this.state}
                          columns={2}
                          pagination={false}
                          excerpt={false}
                          pageNumber="1"
                          Posts={LatestVideosPosts}
                          sidescroll={false}
                          Loading={Loading}
                        />
                      ) : (
                        <GridComponent2
                          addClass="side-scroll-network"
                          itemsCount={4}
                          state={this.state}
                          columns={2}
                          pagination={false}
                          excerpt={false}
                          pageNumber="1"
                          Posts={LatestVideosPostsCat}
                          sidescroll={false}
                          Loading={Loading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="side-video-box">
                  {!Posts.showData && !Loading ? (
                    <>
                      <h4 className="side-heading mt-2 mb-2 uppercase">
                        TRENDING VIDEO
                      </h4>
                      <div className="side-video-img">
                        <div id="botr_acKr2ukJ_owsYDZVC_div"></div>
                      </div>
                      {/* <Sas97587Component /> */}
                    </>
                  ) : (
                    ``
                  )}

                  <h4 className="side-heading mt-2 mb-2 uppercase">
                    {LatestPosts.length >= 1 ? `RELATED ARTICLES` : ``}
                  </h4>
                  <div className="row">
                    <GridComponent2
                      addClass="side-scroll-network"
                      itemsCount={2}
                      state={this.state}
                      columns={1}
                      pagination={false}
                      excerpt={false}
                      pageNumber="1"
                      Posts={LatestPosts}
                      sidescroll={false}
                      Loading={Loading}
                    />
                  </div>

                  {/* <Sas97469Component />
                  <Sas974692Component/> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Sas97722Component/> */}

        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered">
            {/* dangerouslySetInnerHTML={{ __html: content }}> */}
          </div>
        </section>
      </>
    );
  }
}
export default withParams(SingleWatchComponent);
