export function StructuredDataListScript(postData, urlslug, name, description) {
  const itemListElementArray = postData.map((post, index) => ({
    "@type": "ListItem",
    position: index + 1,
    item: {
      "@type": "Article",
      name: post.post_title,
      image: [
        post.post_image.thumbnail_image_url,
        post.post_image.full_image_url,
        post.post_image.medium_image_url,
        post.post_image.custom_image_url,
      ],
      datePublished: post.publish_date,
      dateModified: post.publish_date,
      author: [
        {
          "@type": "Person",
          name: post.author_name,
          url: `https://latv.com/author/${post.author_username}/`,
        },
      ],
    },
  }));
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    url: `https://latv.com/${urlslug}/`,
    name: name,
    description: description,
    itemListElement: itemListElementArray,
  };
  return structuredData;
}
