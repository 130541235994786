import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import LoadingPost from "../../images/loading.jpg";
// import playbutton from '../../Images/play-button.png';
// import WatchEpisodePlaceholderComponent from '../Placeholders/WatchEpisodePlaceholderComponent';

import Pagination from "react-js-pagination";
// import Img from 'react-cool-img';
// import '../../CustomCss/episodesLoad.css';
// import '../../CustomCss/SingleReadCategory.css';
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
import _ from "lodash";
import GridComponent2 from "../SharedComponents/GridComponent2";
export default class SpecialsArticlesLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingArticles: true,
      Articles: [],
      ArticlesTotalPages: 1,
      ArticlesActivePage: 1,
      itemscount: 9,
      pagination: "yes",
      operator: "or",
      excerpt: "no",
    };
  }
  componentDidMount() {
    this.loadArticles(1);
    this.setState({
      itemscount: this.props.itemscount,
      operator: this.props.operator,
      excerpt: this.props.excerpt,
    });
  }
  loadArticles = async (pageNumber) => {
    await axios
      .get(
        `/get-special-related-articles?page_no=${pageNumber}&category=${this.props.category}&tag=${this.props.post_tag}&itemscount=${this.props.itemscount}&operator=${this.props.operator}&excerpt=${this.props.excerpt}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data[0].ID);
        this.setState({
          Articles: res.data.data,
          LoadingArticles: false,
          ArticlesTotalPages: res.data.pagecount,
          ArticlesActivePage: pageNumber,
          listScript: StructuredDataListScript(
            res.data.data,
            this.props.post_tag,
            "Articles Section",
            `Articles Section from ${this.props.post_tag}`
          ),
        });
        localStorage.setItem("contextlyID", res.data.data[0].ID);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleArticlesPageChange(pageNumber) {
    this.loadArticles(pageNumber);
    this.setState({ LoadingArticles: true, ArticlespageNumber: pageNumber });
  }

  render() {
    const { LoadingArticles, Articles, pagination, itemscount } = this.state;
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(this.state.listScript)}
          </script>
        </Helmet>
        {Articles && Articles !== null ? (
          <>
            <h2 className="blue-text underline">{this.props.title}</h2>
            {/* <h2 className="blue-text underline">SPECIAL ARTICLES</h2> */}
          </>
        ) : (
          ``
        )}

        <div className="row shortcode">
          <GridComponent2
            itemsCount={6}
            state={this.state}
            columns={3}
            pagination={false}
            excerpt={true}
            pageNumber="1"
            Posts={Articles}
            Loading={LoadingArticles}
            gridAds={false}
            clips={false}
            seriesSpecials={true}
          />
          {/* {!LoadingArticles && Articles ? (
            Articles.slice(0, itemscount).map((article) => {
              const {
                ID,
                post_title,
                post_image,
                post_slug,
                imgs,
                post_type,
                post_category,
                publish_date,
                excerpt,
              } = article;
              const articleHref =
                post_type === "watch"
                  ? `../../watch/${post_slug}`
                  : `/${post_slug}`;
              return (
                <div
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-2"
                  key={ID}
                >
                  <a href={articleHref}>
                    <div className="botto m-read-img">
                      <img
                        placeholder={LoadingPost}
                        sizes={imgs.toReversed().map((image, index) => {
                          if (image[1] === undefined) {
                            return [];
                          }
                          let Bsize = "";
                          const Size = [
                            "(-webkit-min-device-pixel-ratio: 2) 50vw",
                          ];
                          const size = image[1].toString();
                          if (index === 0) {
                            Size.push(
                              "(min-width " + size + "px) " + size + "w"
                            );
                            Bsize = size - 1;
                          } else {
                            Size.push(
                              "(max-width " + Bsize + "px) " + size + "w"
                            );
                            Bsize = size - 1;
                          }
                          return Size;
                        })}
                        srcSet={imgs.toReversed().map((image, index) => {
                          if (image[1] === undefined) {
                            return [];
                          }
                          const Srcset = [];
                          const Zize = image[1].toString();
                          const link = image[0].toString();
                          Srcset.push(link + " " + Zize + "w");
                          return Srcset;
                        })}
                        src={post_image.medium_image_url}
                        loading="lazy"
                        cache
                        className="img-fluid w-100 h-auto"
                        alt={post_image.image_alt}
                      />
                    </div>
                    <div className="bottomRead-Content">
                      <div className="bottom-read-heading ">
                        <span
                          className="bottom-read-category"
                          data-toggle="tooltip"
                          title={
                            post_category[0] &&
                            post_category[0].cat_name !== null
                              ? post_category[0].cat_name
                              : ``
                          }
                        >
                          {post_category[0] &&
                          post_category[0].cat_name !== null
                            ? post_category[0].cat_name.length > 16
                              ? post_category[0].cat_name.substr(0, 16) + `...`
                              : post_category[0].cat_name
                            : ``}
                        </span>{" "}
                        <span className="bottom-read-heading-span">
                          {publish_date}
                        </span>
                      </div>
                      <div>
                        <h2 className="grid-post-titles">
                          {post_title && post_title !== null ? post_title : ``}
                        </h2>
                      </div>
                      <div className="line-clamp excerpt">
                        {this.state.excerpt === "yes" ? excerpt : ``}
                      </div>
                      <div className="read-more mr-2">
                        READ MORE <i className="fa fa-arrow-right"></i>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <>
              {Articles !== null ? (
                <>
                  {_.times(itemscount, (i) => (
                    <></>
                    // <WatchEpisodePlaceholderComponent key={i}/>
                  ))}
                </>
              ) : (
                ``
              )}
            </>
          )} */}
        </div>
        {pagination === "yes" &&
        Articles !== null &&
        !LoadingArticles &&
        Articles ? (
          <>
            <div className="center pt-2">
              <Pagination
                activePage={this.state.ArticlesActivePage}
                itemsCountPerPage={1}
                totalItemsCount={this.state.ArticlesTotalPages}
                pageRangeDisplayed={5}
                onChange={this.handleArticlesPageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination watch-page-pagination"
                hideFirstLastPages
                prevPageText="<"
                nextPageText=">"
              />
            </div>
          </>
        ) : (
          ``
        )}
      </>
    );
  }
}
