import React, { Component } from "react";
import axios from "../apiConnections/axiosstaticpages";
import axiosmain from "../apiConnections/axios";
import $ from "jquery";
import { Helmet } from "react-helmet";
import "../styles/contactus.css";
import { Html5Entities } from "html-entities";

export default class ContactUsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      title: "",
      content: "",
      cname: "",
      email: "",
      phone: "",
      message: "",
      alertclass: "",
      alerttype: "",
      alertmessage: "",
      formIsValid: true,
      nameError: "",
      emailError: "",
      phoneError: "",
      messageError: "",
      mainPostCategory: "corporate",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
    };
  }
  componentDidMount() {
    this.loadAbout();
    // $('[data-toggle="tooltip"]').tooltip();
    window.scrollTo(0, 0);
    // ReactGA.initialize('UA-3906388-4'); //UA-3906388-4
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }
  // componentWillUnmount() {
  //   window.location.reload();
  // }
  loadAbout = async () => {
    this.setState({ Loading: true });
    await axios
      .get(`/pages/?slug=contact-us&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({ title: res.data[0].title.rendered });
        this.setState({ content: res.data[0].content.rendered });
        this.setState({ Loading: false });
        // $('[data-toggle="tooltip"]').tooltip();
        // $("[rel=tooltip]").tooltip({ trigger: "hover" });
        this.appendLotameScript();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleValidation() {
    let cname = this.state.cname;
    let email = this.state.email;
    let phone = this.state.phone;
    let message = this.state.message;

    let formIsValid = true;

    //Name
    if (!cname || typeof cname === "undefined") {
      formIsValid = false;
      this.setState({
        nameError: "Name should not be empty.",
      });
    } else if (typeof cname !== "undefined" && cname !== "") {
      var pattern = new RegExp(/^(?:[a-zA-Z][a-zA-Z\s]*)?$/);

      if (!pattern.test(cname)) {
        formIsValid = false;
        this.setState({
          nameError: "Name should not contain any special chars.",
        });
      }
    }
    //Message
    if (!message || typeof message === "undefined") {
      formIsValid = false;
      this.setState({
        messageError: "Message should not be empty.",
      });
    }
    //Phone
    if (!phone || typeof phone === "undefined") {
      formIsValid = false;
      this.setState({
        phoneError: "Phone No. should not be empty.",
      });
    } else if (typeof phone !== "undefined") {
      if (!phone.match(/^\d+$/)) {
        formIsValid = false;
        this.setState({
          phoneError: "Phone No. should be only in numbers.",
        });
      }
    } else if (phone.length < 9 || phone.length > 15) {
      formIsValid = false;
      this.setState({
        phoneError: "Invalid Phone Number",
      });
    }

    //Email
    if (!email) {
      formIsValid = false;
      this.setState({
        emailError: "Email address should not be empty.",
      });
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        formIsValid = false;
        this.setState({
          emailError: "Invalid Email address",
        });
      } else if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        this.setState({
          emailError: "Invalid Email address",
        });
      }
    }
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      const contactinfo = {
        name: this.state.cname,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
      };

      axiosmain
        .post(`/send-mail`, contactinfo)
        .then((res) => {
          if (res.data.code === 1) {
            this.setState({
              alertclass: res.data.messagetype,
              alerttype: "Success! ",
              alertmessage: res.data.message,
              cname: "",
              email: "",
              phone: "",
              message: "",
            });
            $("#wpforms-form-contact").trigger("reset");
            $("#wpforms-form-contact").reset();
          }
          if (res.data.code === 2) {
            this.setState({
              alertclass: res.data.messagetype,
              alerttype: "Error! ",
              alertmessage: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };
  handleNameChange = (event) => {
    var cname = event.target.value;

    if (!cname || typeof cname === "undefined") {
      this.setState({
        nameError: "Name should not be empty.",
      });
    } else if (typeof cname !== "undefined" && cname !== "") {
      var pattern = new RegExp(/^(?:[a-zA-Z][a-zA-Z\s]*)?$/);

      if (!pattern.test(cname)) {
        this.setState({
          nameError: "Name should not contain any special chars.",
        });
      } else {
        this.setState({ nameError: null });
        this.setState({ cname: event.target.value });
      }
    }
  };
  handleEmailChange = (event) => {
    var email = event.target.value;
    //Email
    if (!email) {
      this.setState({
        emailError: "Email address should not be empty.",
      });
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        this.setState({
          emailError: "Invalid Email address",
        });
      } else if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        this.setState({
          emailError: "Invalid Email address",
        });
      } else {
        this.setState({ emailError: null });
        this.setState({ email: event.target.value });
      }
    }
  };
  handlePhoneChange = (event) => {
    //Phone
    var phone = event.target.value;

    if (!phone || typeof phone === "undefined") {
      this.setState({
        phoneError: "Phone No. should not be empty",
      });
    } else if (typeof phone !== "undefined") {
      if (!phone.match(/^\d+$/)) {
        this.setState({
          phoneError: "Phone No. should be only in number",
        });
      } else if (phone.length < 9 || phone.length > 15) {
        this.setState({
          phoneError: "Invalid Phone Number",
        });
      } else {
        this.setState({ phoneError: null });
        this.setState({ phone: event.target.value });
      }
    }
  };
  handleMessageChange = (event) => {
    var message = event.target.value;

    if (!message || typeof message === "undefined") {
      this.setState({
        messageError: "Message Should not be empty.",
      });
    } else {
      this.setState({ messageError: null });
      this.setState({ message: event.target.value });
    }
  };
  /* Lotame Stuff*/
  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    // console.log(document.getElementById('lotameTag'));
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    // console.log("lotame load")
    let path_name = window.location.pathname;
    let url = window.location.href;
    // console.log(this.state.mainPostCategory);
    let article_tags = [];
    let article_cats = [];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }
  /* Lotame Stuff*/
  render() {
    const {
      Loading,
      title,
      content,
      alertclass,
      alertmessage,
      alerttype,
      nameError,
      emailError,
      phoneError,
      messageError,
    } = this.state;
    // const htmlEntities = new Html5Entities();

    return (
      <>
        {/* <Helmet>
          <title>{`${htmlEntities.decode(title)} | LATV`}</title>
          <meta name="title" content={`${htmlEntities.decode(title)} | LATV`} />
          <meta
            name="description"
            content={`NEW YORK / SALES HQ 420 Lexington Ave. Suite 902 New York, NY 10170 LOS ANGELES / STUDIOS 2323 Corinth Ave. Los Angeles&#8230;`}
          />
        </Helmet> */}
        <section id="read-section" className="read-section ">
          <div className="container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* <h2 className="about-heading">
                            {!Loading ? (title) : ``}
                            </h2> */}
                  {!Loading ? (
                    <>
                      <p
                        className="about-info"
                        dangerouslySetInnerHTML={{ __html: content }}
                      ></p>
                    </>
                  ) : (
                    ``
                  )}
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="container border-enable">
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-71b110 elementor-section-boxed elementor-section-height-default elementor-section-height-default "
                  data-id="71b110d"
                  data-element_type="section"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aec5c49"
                        data-id="aec5c49"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-e362a27 elementor-widget elementor-widget-heading"
                              data-id="e362a27"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  Let's Connect
                                </h2>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-d17fd73 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                              data-id="d17fd73"
                              data-element_type="widget"
                              data-widget_type="divider.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-divider">
                                  <span className="elementor-divider-separator"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="container  border-enable-2 mb-5">
                <div className="row">
                  <span className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <section
                      className="contact-form elementor-section elementor-top-section elementor-element elementor-element-eaaa22c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="eaaa22c"
                      data-element_type="section"
                      data-settings='{"background_background":"classic"}'
                    >
                      {alertclass !== "" ? (
                        <div
                          className={`alert alert-${alertclass} elementor-col-66`}
                        >
                          <strong>{alerttype}</strong> {alertmessage}
                        </div>
                      ) : (
                        ``
                      )}
                      <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                          <div
                            className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-e60f120a"
                            data-id="e60f80a"
                            data-element_type="column"
                          >
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div
                                  className="elementor-element elementor-element-9e485f2 elementor-widget elementor-widget-wp-widget-wpforms-widget"
                                  data-id="9e485f2"
                                  data-element_type="widget"
                                  data-widget_type="wp-widget-wpforms-widget.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      className="wpforms-container wpforms-container-full"
                                      id="wpforms-504"
                                    >
                                      <form
                                        onSubmit={this.handleSubmit}
                                        id="wpforms-form-contact"
                                        className="wpforms-validate wpforms-form"
                                        data-formid="504"
                                        method="post"
                                        encType="application/x-www-form-urlencoded"
                                      >
                                        <div className="wpforms-field-container">
                                          <div
                                            id="wpforms-504-field_0-container"
                                            className="wpforms-field wpforms-field-name no-margin-top no-padding-top"
                                            data-field-id="0"
                                          >
                                            <label
                                              className="wpforms-field-label wpforms-label-hide"
                                              htmlFor="wpforms-504-field_0"
                                            >
                                              Name{" "}
                                              <span className="wpforms-required-label">
                                                *
                                              </span>{" "}
                                              <span className="label-danger">
                                                {nameError}
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              onChange={this.handleNameChange}
                                              id="wpforms-504-field_0"
                                              className="wpforms-field-large wpforms-field-required"
                                              name="wpforms[fields][0]"
                                              placeholder="Name *"
                                              required=""
                                            />
                                          </div>
                                          <div
                                            id="wpforms-504-field_1-container"
                                            className="wpforms-field wpforms-field-email"
                                            data-field-id="1"
                                          >
                                            <label
                                              className="wpforms-field-label wpforms-label-hide"
                                              htmlFor="wpforms-504-field_1"
                                            >
                                              Email{" "}
                                              <span className="wpforms-required-label">
                                                *
                                              </span>
                                              <span className="label-danger">
                                                {emailError}
                                              </span>
                                            </label>
                                            <input
                                              type="email"
                                              onChange={this.handleEmailChange}
                                              id="wpforms-504-field_1"
                                              className="wpforms-field-large wpforms-field-required"
                                              name="wpforms[fields][1]"
                                              placeholder="Email *"
                                              required=""
                                            />
                                          </div>
                                          <div
                                            id="wpforms-504-field_3-container"
                                            className="wpforms-field wpforms-field-text"
                                            data-field-id="3"
                                          >
                                            <label
                                              className="wpforms-field-label wpforms-label-hide"
                                              htmlFor="wpforms-504-field_3"
                                            >
                                              Phone{" "}
                                              <span className="wpforms-required-label">
                                                *
                                              </span>
                                              <span className="label-danger">
                                                {phoneError}
                                              </span>
                                            </label>
                                            <input
                                              type="number"
                                              onChange={this.handlePhoneChange}
                                              id="wpforms-504-field_3"
                                              className="wpforms-field-large"
                                              name="wpforms[fields][3]"
                                              placeholder="Phone *"
                                            />
                                          </div>
                                          <div
                                            id="wpforms-504-field_2-container"
                                            className="wpforms-field wpforms-field-textarea"
                                            data-field-id="2"
                                          >
                                            <label
                                              className="wpforms-field-label wpforms-label-hide"
                                              htmlFor="wpforms-504-field_2"
                                            >
                                              Message{" "}
                                              <span className="wpforms-required-label">
                                                *
                                              </span>
                                              <span className="label-danger">
                                                {messageError}
                                              </span>
                                            </label>
                                            <textarea
                                              onChange={
                                                this.handleMessageChange
                                              }
                                              id="wpforms-504-field_2"
                                              className="wpforms-field-medium wpforms-field-required"
                                              name="wpforms[fields][2]"
                                              placeholder="Your Comment"
                                              required=""
                                            ></textarea>
                                          </div>
                                        </div>
                                        <div className="wpforms-submit-container">
                                          <button
                                            type="submit"
                                            className="wpforms-submit submit-yellow"
                                            id="wpforms-submit-504"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2a8acca"
                            data-id="2a8acca"
                            data-element_type="column"
                          >
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div
                                  className="elementor-element elementor-element-d3b0b20 elementor-widget elementor-widget-text-editor"
                                  data-id="d3b0b20"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="elementor-text-editor elementor-clearfix"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
