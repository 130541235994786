export function StructuredDataScript(postData) {
  console.log(postData);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: postData.post_title,
    image: [
      postData.post_image.thumbnail_image_url,
      postData.post_image.full_image_url,
      postData.post_image.medium_image_url,
      postData.post_image.custom_image_url,
    ],
    datePublished: postData.publish_datetime,
    dateModified: postData.publish_datetime,
    author: [
      {
        "@type": "Person",
        name: postData.author_name,
        url: `https://latv.com/author/${postData.author_username}/`,
      },
    ],
  };
  return structuredData;
}
