import loadingSVG from "../../images/loadingSVG.svg";
import React, { Component } from "react";
import ImageResize from "../ImageResize/ImageResize.js";
import { Link } from "react-router-dom";

const GridComponentScroll = ({ post, state, excerpt }) => {
  const {
    ID,
    post_category,
    post_image,
    post_title,
    post_slug,
    publish_date,
    short_description,
    post_type,
  } = post;
  return (
    <>
      <div
        className="col-xl-12 col-lg-12 col-md-12 col-sm-5 col-11 p-xl-0 p-lg-0 p-md-2 p-sm-2 p-2"
        key={ID}
      >
        <div className="fromOurSeries card">
          <a
            href={
              !!post_category.find((element) => element.cat_name === "QUEER")
                ? post_type === "watch"
                  ? `../queer/watch/${post_slug}`
                  : `../queer/${post_slug}`
                : post_type === "watch"
                ? `../watch/${post_slug}`
                : `../${post_slug}/`
            }
          >
            {post_image.full_image_url !== null ? (
              <>
                <ImageResize
                  state={state}
                  serie={post}
                  class="card-img-top img-sizing"
                  alter={post.serieName ? post.serieName : "alter"}
                />
              </>
            ) : (
              <>
                <img
                  src="https://dummyimage.com/1920x1080/ff0000/fff.png&text=Article+Image+1"
                  className="card-img-top img-sizing"
                  alt="..."
                ></img>
              </>
            )}
            <div className="card-img-overlay p-0 rounded-1">
              <div className="text-start">
                <span className="ps-1">
                  {post_category[0] && post_category[0].cat_name !== null
                    ? post_category[0].cat_name.length > 16
                      ? post_category[0].cat_name.substr(0, 16) + `...`
                      : post_category[0].cat_name
                    : ``}
                </span>
              </div>
            </div>
            <div className="date-start mt-2">
              <span>{publish_date}</span>
            </div>
            <div className="card-body p-0 mt-1">
              <h5
                className="card-title"
                dangerouslySetInnerHTML={{
                  __html:
                    post_title && post_title !== null
                      ? post_title.length > 42
                        ? post_title.substr(0, 42) + `...`
                        : post_title
                      : ``,
                }}
              ></h5>
              <p className="card-text text-body-secondary mb-0 line-clamp">
                {short_description && excerpt && short_description !== null
                  ? short_description
                  : ``}
              </p>
            </div>
            <div className="mt-2">
              <a href={"/" + post_slug + "/"}>
                <div className="main-box-footer">
                  READ MORE{" "}
                  <i className="fa fa-chevron-right d-none d-md-inline"></i>
                </div>
              </a>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default GridComponentScroll;
