import React, { Component } from "react";
import logo from "../../images/LATV-new-logo-white.png";
const actualYear = new Date().getFullYear();
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Menus: [],
      igSlug: "",
      ttkSlug: "",
    };
  }

  isSlug(slug) {
    return this.props.location.startsWith(slug);
  }

  componentDidMount() {
    let igSlug;
    switch (true) {
      case this.isSlug("/culture"):
        igSlug = "latinationculture";
        break;
      case this.isSlug("/latina"):
        igSlug = "latinationlatina";
        break;
      case this.isSlug("/afrolatino"):
        igSlug = "latinationroots";
        break;

      default:
        igSlug = "latinationmedia";
        break;
    }
    let ttkSlug;
    switch (true) {
      case this.isSlug("/latina"):
        ttkSlug = "latinationlatina";
        break;
      case this.isSlug("/afrolatino"):
        ttkSlug = "latinationroots";
        break;

      default:
        ttkSlug = "latinationmedia";
        break;
    }
    this.setState({ igSlug: igSlug, ttkSlug: ttkSlug });
  }

  render() {
    return (
      <>
        <section id="footer-section" className="footer-section">
          <div className="row p-lg-5 p-md-5 p-sm-5 p-4 mt-2 ">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <a href="/">
                <div className="footer-logo ">
                  <img
                    className="footer-logo-img no-js-wQYACoR+I8i5xH2AAAAAElFTkSuQmCC"
                    src={logo}
                    alt="LATV-logo"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Latv"
                  />
                </div>
              </a>
              <p
                className="m-lg-0 m-md-0 m-sm-0 mb-3 mt-2 pe-5"
                style={{ fontSize: "14px" }}
              >
                Media Representing The LatiNationLATV Networks, LLC © 2024
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 align-center">
              <ul className="footer-menu list-unstyled card-columns ">
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/about-us/">ABOUT US</a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://partners.latv.com/cookie/">
                    ADVERTISE WITH US
                  </a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/contact-us/">CONTACT US</a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/job/s">JOBS</a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/privacy-policy/">PRIVACY POLICY</a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/schedule/">SCHEDULE</a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/terms-conditions/">
                    TERMS &amp; CONDITIONS
                  </a>
                </li>
                <li className="f_list_item ps-md-3 ps-lg-3 ps-sm-3 ps-0">
                  <a href="https://latv.com/schedule/">WHERE TO WATCH</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 d-flex social-media-justification">
              <a
                href={"https://www.tiktok.com/@" + this.state.ttkSlug}
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="btn fa-brands fa fa-tiktok footer-icon white-txt"></i>
              </a>
              <a
                href={"https://www.instagram.com/" + this.state.igSlug}
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="btn fa-brands fa fa-instagram footer-icon white-txt"></i>
              </a>
              <a
                href="https://www.facebook.com/LatvNetwork/"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <i className="btn fa-brands fa fa-facebook footer-icon white-txt"></i>
              </a>
              <a
                href="https://www.youtube.com/user/LATVNetwork/"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
              >
                <i className="btn fa-brands fa fa-youtube  footer-icon white-txt"></i>
              </a>
              <a
                href="https://twitter.com/latvnetwork/"
                target="_blank"
                rel="noreferrer"
                title="Twitter"
              >
                <i className="btn fa-brands fa fa-x-twitter footer-icon white-txt"></i>
              </a>
            </div>
          </div>
        </section>
        {/* <section id="post-footer" className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-6 col-12 ">
              <div className="col">Media Representing The LatiNation</div>
              <div className="col main-box-content">
                LATV Networks, LLC © {actualYear}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12 socialNetworks">
              <a
                href={"https://www.instagram.com/" + this.state.igSlug}
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="btn fa-brands fa fa-instagram"></i>
              </a>
              <a
                href={"https://www.tiktok.com/@" + this.state.ttkSlug}
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="btn fa-brands fa fa-tiktok"></i>
              </a>
              <a
                href="https://www.facebook.com/LatvNetwork/"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <i className="btn fa-brands fa fa-facebook"></i>
              </a>
              <a
                href="https://www.youtube.com/user/LATVNetwork/"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
              >
                <i className="btn fa-brands fa fa-youtube"></i>
              </a>
              <a
                href="https://twitter.com/latvnetwork/"
                target="_blank"
                rel="noreferrer"
                title="Twitter"
              >
                <i className="btn fa-brands fa fa-twitter"></i>
              </a>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}
export default Footer;
