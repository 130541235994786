import React, { Component } from "react";
import axios from "../../apiConnections/axios";
// import Img from 'react-cool-img';
import Pagination from "react-js-pagination";
import $ from "jquery";
// import Sas97469Component from './Ads/Sas97469Component';
// import Sas974692Component from './Ads/Sas974692Component';
// import Sas97587Component from './Ads/Sas97587Component';
// import Sas97587222Component from './Ads/Sas97587222Component';
// import Sas97588Component from './Ads/Sas97588Component';
import WatchClipPlaceholderComponent from "../../Placeholders/WatchClipPlaceholderComponent";
import WatchEpisodePlaceholderComponent from "../../Placeholders/WatchEpisodePlaceholderComponent";
// import WatchPageAdsComponent from "./AdsJs/WatchPageAdsComponent";

import "../../styles/watchpage.css";
import GridComponent2 from "../SharedComponents/GridComponent2";
export class WatchPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Series: [],
      Clips: [],
      Episodes: [],
      LoadingSeries: true,
      LoadingClips: true,
      LoadingEpisodes: true,
      Loading: true,
      ClipsactivePage: 1,
      ClipspageNumber: 1,
      ClipstotalPages: 1,
      EpisodesactivePage: 1,
      EpisodespageNumber: 1,
      EpisodestotalPages: 1,
      EpisodePerpage: 6,
      status: "start",
      mainPostCategory: "",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
    };
  }

  componentDidMount() {
    this.loadSeries();
    this.loadClips(this.state.ClipspageNumber);
    this.loadEpisodes(this.state.EpisodespageNumber, this.state.EpisodePerpage);
    // $(".tooltip").hide();
    // $('[data-toggle="tooltip"]').tooltip();
  }
  //   componentWillUnmount() {
  //     window.location.reload();
  //   }

  CarruselBehavior() {
    function Carousel() {
      let items = document.querySelectorAll(
        "#seriesCarousel.carousel .carousel-item"
      );
      items.forEach((el) => {
        const minPerSlide = 6;
        let next = el.nextElementSibling;
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child
            next = items[0];
          }
          let cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
        }
      });
    }

    function checkCarouselExistence() {
      if ($("#seriesCarousel.carousel .carousel-item").length > 0) {
        Carousel();
      } else {
        setTimeout(checkCarouselExistence, 100);
      }
    }

    checkCarouselExistence();
  }

  loadSeries = async () => {
    this.setState({ Loading: true });
    await axios
      .get(`/get-footer-slide-post?timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({ Series: res.data.data });
        this.setState({ LoadingSeries: false });
        this.CarruselBehavior();
        // $("#myCarousel").carousel({
        //   interval: 5000,
        // });
        // $(".carousel .carousel-item").each(function () {
        //   var minPerSlide = 4;
        //   var next = $(this).next();
        //   if (!next.length) {
        //     next = $(this).siblings(":first");
        //   }
        //   next.children(":first-child").clone().appendTo($(this));

        //   for (var i = 0; i < minPerSlide; i++) {
        //     next = next.next();
        //     if (!next.length) {
        //       next = $(this).siblings(":first");
        //     }
        //     next.children(":first-child").clone().appendTo($(this));
        //   }
        //   if ($(window).width() < 750) {
        //     $(".caro-image").addClass("offset-2");
        //   }
        // });
        this.appendLotameScript();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* Lotame Stuff*/
  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    // console.log(document.getElementById('lotameTag'));
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    // console.log("lotame load")
    let path_name = window.location.pathname;
    let url = window.location.href;
    // console.log(this.state.mainPostCategory);
    let article_tags = [];
    let article_cats = [];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }
  /* Lotame Stuff*/

  loadClips = async (pageNumber) => {
    await axios
      .get(
        `/get-read-page-video-posts?page_no=${pageNumber}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          Clips: res.data.data,
          LoadingClips: false,
          ClipstotalPages: res.data.pagecount,
          ClipsactivePage: pageNumber,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadEpisodes = async (pageNumber, perPage) => {
    await axios
      .get(
        `/get-home-page-video-posts?page_no=${pageNumber}&per_page=${perPage}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          Episodes: res.data.data,
          LoadingEpisodes: false,
          EpisodestotalPages: res.data.pagecount,
          EpisodesactivePage: pageNumber,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleEpisodePageChange(pageNumber) {
    this.loadEpisodes(pageNumber, this.state.EpisodePerpage);
    this.setState({ LoadingEpisodes: true });
    this.setState({ EpisodespageNumber: pageNumber });
  }
  handleClipsPageChange(pageNumber) {
    this.loadClips(pageNumber);
    this.setState({ LoadingClips: true });
    this.setState({ ClipspageNumber: pageNumber });
  }

  do_load = () => {
    var script2 = document.createElement("script");
    script2.src = "https://cdn.jwplayer.com/players/7DSt3ibJ-d79qnpgi.js";
    script2.async = true;
    document.body.appendChild(script2);
    var script3 = document.createElement("script");
    script3.src = "https://cdn.jwplayer.com/players/8hkLPI9d-cMdkGZqS.js";
    script3.async = true;
    document.body.appendChild(script3);
  };
  render() {
    let Bsize = "";
    var self = this;
    if (self.state.status === "start") {
      self.state.status = "loading";
      self.do_load();
    }

    const {
      LoadingClips,
      LoadingSeries,
      LoadingEpisodes,
      Clips,
      Series,
      Episodes,
      EpisodePerpage,
    } = this.state;
    return (
      <>
        <section id="read-section" className="read-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h4 className="read-page-heading mb-3">Watch</h4>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div id="botr_8hkLPI9d_cMdkGZqS_div"></div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                {/* <Sas97469Component/>
                        <Sas974692Component/> */}
              </div>
            </div>
          </div>
        </section>

        <section id="gallery-section2" className="gallery-section2 blue-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h4 className=" underline pb-3">Series</h4>
                {/* <div
                  id="myCarousel"
                  className="carousel slide carousel-fade w-100"
                  data-ride="carousel"
                >
                  <div className="carousel-inner w-100" role="listbox">
                    <>
                      {!LoadingSeries ? (
                        Series.slice(0, 1).map((post, i) => {
                          return (
                            <div className="carousel-item active row" key={i}>
                              <div className="col-lg-3 col-md-3 col-8 caro-image">
                                <a href={post.image_link_to}>
                                  <img
                                    className="img-fluid img-responsive lazyload"
                                    src={post.post_image.medium_image_url}
                                    alt={"LATV" + i}
                                  />
                                </a>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {!LoadingSeries ? (
                        Series.slice(1, Series.length).map((post, i) => {
                          return (
                            <div className="carousel-item row" key={i}>
                              <div className="col-lg-3 col-md-3 col-8 caro-image">
                                <a href={post.image_link_to}>
                                  <img
                                    className="img-fluid img-responsive lazyload"
                                    src={post.post_image.medium_image_url}
                                    alt={"LATV" + i}
                                  />
                                </a>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                  <button
                    className="carousel-control-prev w-auto"
                    data-target="#myCarousel"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next w-auto"
                    data-target="#myCarousel"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </button>
                </div> */}
                <div
                  id="seriesCarousel"
                  className="carousel carousel-dark slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner" role="listbox">
                    <>
                      {!LoadingSeries ? (
                        Series.slice(0, 1).map((post, i) => {
                          return (
                            <div className="carousel-item active " key={i}>
                              <div className="col-lg-3 col-md-4 col-12 p-md-1 p-3">
                                <div className="card">
                                  <img
                                    className="img-fluid w-100 card-img-top img-sizing img-sizing"
                                    src={post.post_image.medium_image_url}
                                    alt={"LATV" + i}
                                    width="1000px"
                                    height="563px"
                                  />
                                  <div className="card-body">
                                    <h6 className="card-title">
                                      {post.serieName}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {!LoadingSeries ? (
                        Series.slice(1, Series.length).map((post, i) => {
                          return (
                            <div className="carousel-item " key={i}>
                              <div className="col-lg-3 col-md-4 col-12 p-md-1 p-3">
                                <div className="card">
                                  <img
                                    className="img-fluid w-100 card-img-top img-sizing"
                                    src={post.post_image.medium_image_url}
                                    alt={"LATV" + i}
                                    width="1000px"
                                    height="563px"
                                  />
                                  <div className="card-body">
                                    <h6 className="card-title">
                                      {post.serieName}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                  <a
                    className="carousel-control-prev bg-transparent w-aut"
                    href="#seriesCarousel"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                  </a>
                  <a
                    className="carousel-control-next bg-transparent w-aut"
                    href="#seriesCarousel"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                  </a>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="d-flex pt-5"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="video-section" className="video-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="clips_div">
                  <h4 className="blue-text underline">Clips</h4>
                  <div className="row">
                    <GridComponent2
                      numPosts={9}
                      state={this.state}
                      columns={3}
                      pagination={false}
                      excerpt={true}
                      pageNumber="1"
                      Posts={Clips}
                      Loading={LoadingClips}
                      gridAds={false}
                      clips={true}
                    />
                    {/* {!LoadingClips ? (
                      Clips.slice(0, 9).map((clips) => {
                        const {
                          ID,
                          post_title,
                          post_image,
                          post_slug,
                          publish_date,
                          imgs,
                        } = clips;
                        return (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-2"
                            key={ID}
                          >
                            <a href={`../watch/${post_slug}`}>
                              <div className="mt-3">
                                <div className="side-video-thumbnail">
                                  <img
                                    placeholder={LoadingPost}
                                    sizes={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Size = [
                                          "(-webkit-min-device-pixel-ratio: 2) 50vw",
                                        ];
                                        const size = image[1].toString();
                                        if (index === 0) {
                                          Size.push(
                                            "(min-width " +
                                              size +
                                              "px) " +
                                              size +
                                              "w"
                                          );
                                          Bsize = size - 1;
                                        } else {
                                          Size.push(
                                            "(max-width " +
                                              Bsize +
                                              "px) " +
                                              size +
                                              "w"
                                          );
                                          Bsize = size - 1;
                                        }
                                        return Size;
                                      })}
                                    srcSet={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset;
                                      })}
                                    src={post_image.medium_image_url}
                                    loading="lazy"
                                    cache
                                    className="img-fluid w-100 h-auto"
                                    alt={post_image.image_alt}
                                  />
                                  <div>
                                    <img
                                      src={playbutton}
                                      className="img-fluid"
                                      alt={"play-button"}
                                    />
                                  </div>
                                </div>
                                <div className="date-small-video mt-2">
                                  {publish_date}
                                </div>
                                <div
                                  className="title-small-video mt-1"
                                  title={post_title}
                                  data-toggle="tooltip"
                                >
                                  {post_title !== null && post_title !== ""
                                    ? post_title.length > 50
                                      ? post_title.substr(0, 50) + `...`
                                      : post_title
                                    : ``}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                        <WatchClipPlaceholderComponent />
                      </>
                    )} */}
                  </div>
                </div>
                <div className="center pt-2">
                  <Pagination
                    activePage={this.state.ClipsactivePage}
                    itemsCountPerPage={1}
                    totalItemsCount={this.state.ClipstotalPages}
                    pageRangeDisplayed={5}
                    onChange={this.handleClipsPageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination watch-page-pagination"
                    hideFirstLastPages
                    prevPageText="<"
                    nextPageText=">"
                  />
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className={`side-video-box`}>
                  <h4 className="side-heading">TRENDING VIDEO</h4>
                  <div className="side-video-img">
                    <div id="botr_7DSt3ibJ_d79qnpgi_div"></div>
                  </div>
                </div>
                {/* <Sas97587Component/> */}
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="pt-4 pb-4 bottom-advertise">
                  {/* <Sas97588Component/> */}
                </div>
              </div>

              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="episodes_div">
                  <h4 className="blue-text underline">Full Episodes</h4>
                  <div className="row ">
                    <GridComponent2
                      numPosts={EpisodePerpage}
                      state={this.state}
                      columns={3}
                      pagination={false}
                      excerpt={true}
                      pageNumber="1"
                      Posts={Episodes}
                      Loading={LoadingEpisodes}
                      gridAds={false}
                      clips={true}
                    />
                    {/* {!LoadingEpisodes ? (
                      Episodes.slice(0, EpisodePerpage).map((clips) => {
                        const {
                          ID,
                          post_title,
                          post_image,
                          post_slug,
                          publish_date,
                          imgs,
                        } = clips;
                        return (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-2"
                            key={ID}
                          >
                            <a href={`../watch/${post_slug}`}>
                              <div className="mt-3">
                                <div className="side-video-thumbnail opt-out-border">
                                  <img
                                    placeholder={LoadingPost}
                                    sizes={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Size = [
                                          "(-webkit-min-device-pixel-ratio: 2) 50vw",
                                        ];
                                        const size = image[1].toString();
                                        if (index === 0) {
                                          Size.push(
                                            "(min-width " +
                                              size +
                                              "px) " +
                                              size +
                                              "w"
                                          );
                                          Bsize = size - 1;
                                        } else {
                                          Size.push(
                                            "(max-width " +
                                              Bsize +
                                              "px) " +
                                              size +
                                              "w"
                                          );
                                          Bsize = size - 1;
                                        }
                                        return Size;
                                      })}
                                    srcSet={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset;
                                      })}
                                    src={post_image.medium_image_url}
                                    loading="lazy"
                                    cache
                                    className="img-fluid w-100 h-auto"
                                    alt={post_image.image_alt}
                                  />
                                  <div>
                                    <img
                                      src={playbutton}
                                      className="img-fluid"
                                      alt={"play-button"}
                                    />
                                  </div>
                                </div>
                                <div className="date-small-video mt-2">
                                  {publish_date}
                                </div>
                                <div
                                  className="title-small-video mt-1"
                                  title={post_title}
                                  data-toggle="tooltip"
                                >
                                  {post_title !== null && post_title !== ""
                                    ? post_title.length > 50
                                      ? post_title.substr(0, 50) + `...`
                                      : post_title
                                    : ``}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <WatchEpisodePlaceholderComponent />
                        <WatchEpisodePlaceholderComponent />
                        <WatchEpisodePlaceholderComponent />
                        <WatchEpisodePlaceholderComponent />
                        <WatchEpisodePlaceholderComponent />
                        <WatchEpisodePlaceholderComponent />
                      </>
                    )} */}
                  </div>
                </div>
                <div className="center pt-5 pb-4">
                  <Pagination
                    activePage={this.state.EpisodesactivePage}
                    itemsCountPerPage={1}
                    totalItemsCount={this.state.EpisodestotalPages}
                    pageRangeDisplayed={5}
                    onChange={this.handleEpisodePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination watch-page-pagination"
                    hideFirstLastPages
                    prevPageText="<"
                    nextPageText=">"
                  />
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                {/* <Sas97587222Component/> */}
              </div>
            </div>
          </div>
        </section>

        <div className="mb-5"></div>
        {/* <WatchPageAdsComponent /> */}
      </>
    );
  }
}
export default WatchPageComponent;
