import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import { useParams } from "react-router-dom";
import $ from "jquery";
import InfiniteScroll from "react-infinite-scroll-component";
import SpecialsComponent from "../SharedComponents/SpeciaslComponent.js";
import MailchimpFormComponent from "../SharedComponents/MailchimpFormComponent.js";
import { appendLotameScript } from "../../CustomHooks/AppendLotameScript.js";
import { CreateElementFromHTML } from "../../CustomHooks/CreateElementFromHTMLString.js";
import GridComponent from "../SharedComponents/GridComponent.js";
import GridArticleComponent from "../SharedComponents/GridArticleComponent.js";
import loadingSVG from "../../images/loadingSVG.svg";
import { getScriptId } from "../../CustomHooks/GetScriptId.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import { getPageId } from "../pagesId/smartPageId.js";
import { getAdFunction } from "../Ads/AdsFunctions/getAdFunction.js";
import { initializeSas } from "../Ads/AdsFunctions/initilizeSas.js";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

let flagApi = false;
let flagAds = false;

class CategoryTemporal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      pageNumber: 1,
      searchSlug: "",
      status: "start",
      activePage: 1,
      totalPages: 1,
      hasMore: true,
      mobileDevice: false,
      TrendingVideoId: null,
      TrendingVideoSrc: null,
      category: "",
      mainCategory: "",
      subCategories: [],
      subCat: "",
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
      siteId: 391880,
      hasSpecials: false,
      LoadingSeries: true,
      listScript: "",
    };
  }

  componentDidMount() {
    // loadPosts(1,this.state);
    if (!flagApi) {
      initializeSas();
      this.loadPosts(1).then(() => {
        this.renderTopAd(119621);
        if (!flagAds) {
          this.renderTopAd(119313);
          flagAds = true;
        }
      });
      this.loadSeries();
      window.scrollTo(0, 0);
      $(".tooltip").hide();
      flagApi = true;
    }
  }

  componentWillUnmount() {
    flagApi = false;
  }

  appendAdsToPost(post, pageId) {
    if (!post.adAppended) {
      // console.log("le va a hacer append a el post",post)
      post.adAppended = true;
      const siteId = 391880;

      const bot_content_formats = ["119621"];

      bot_content_formats.forEach((format) => {
        this.appendAd(post.randDivIdBot, format, pageId, siteId, "bot");
      });
    }
  }

  StructuredDataBreadcrumbList = () => {
    let structuredData = {};
    if (this.state.subCat !== undefined && this.state.subCat !== "") {
      structuredData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://latv.com/",
              name: "Home",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://latv.com/${this.state.category}/`,
              name: this.state.category,
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://latv.com/${this.state.category}/${this.state.subCat}/`,
              name: this.state.subCat,
            },
          },
        ],
      };
    } else {
      structuredData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://latv.com/",
              name: "Home",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://latv.com/${this.state.category}/`,
              name: this.state.category,
            },
          },
        ],
      };
    }
    this.setState({
      breadcrumbsListScript: structuredData,
    });
  };

  appendAd(divId, format, pageId, siteId, type) {
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;
    var art = document.createElement("div");
    // console.log(art)
    $(art).addClass(`${type}-adverties`);
    // Create a container in the article with the SMART tag

    var smartDiv = document.createElement("div");
    smartDiv.id = "bannerAd_" + rndId;
    var option = "{ resetTimestamp: true }";
    var smartTag = document.createElement("script");
    var code =
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:'bannerAd_` +
      rndId +
      "'}, " +
      option +
      ");});";
    $(smartTag).text(code);
    art.appendChild(smartDiv);
    art.append(smartTag);
    $("#" + divId).append(art);
  }

  loadPosts = async (pageNumber) => {
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }
    const itemscount = 9; // need to have 9 or 12 condition if mobile
    const category = this.props.category;
    const mainCat = category;
    var customPageNum = this.state.pageNumber;
    let apipath;

    apipath = `/get-post-by-category?page_no=${customPageNum}&itemscount=${itemscount}&category=${category}&timestamp=${new Date().getTime()}`;
    if (typeof this.props.params.slug === "undefined") {
      apipath = `/get-post-by-category?page_no=${customPageNum}&itemscount=${itemscount}&category=${category}&timestamp=${new Date().getTime()}`;
    } else {
      apipath = `/get-post-by-category?page_no=${customPageNum}&category=${category}-${
        this.props.params.slug
      }&timestamp=${new Date().getTime()}`;
    }

    // const pageId = 1701581;
    const pageId = getPageId(mainCat);
    if (!flagApi) {
      const content = getAdFunction(pageId);
      var div = document.createElement("span");
      div.innerHTML = content;

      $("#ads-rendered").html("");
      $("#ads-rendered").append(content);
    }
    await axios
      .get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((resCat) => {
        var catSplit = category.split("-");
        var catTrend;

        catSplit.length > 1
          ? (catTrend = catSplit.shift())
          : (catTrend = category);

        const categoryPrefix = "latv-";
        var apipath = `/get-pilar-playlist-by-slug?slug=${categoryPrefix}${catTrend}`;
        axios
          .get(`${apipath}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          })
          .then((resTrending) => {
            const scriptTag = CreateElementFromHTML(
              resTrending.data.data.post_content
            );
            scriptTag.defer = true;
            const scriptSrc = scriptTag.src;
            //Get Id of script from src
            const scriptId = getScriptId(scriptSrc);
            const paginationPosts = [].concat(resCat.data.data);
            // paginationPosts.forEach((post, index) => {
            //   post.randDivIdBot = parseInt(Math.random() * 1000000000);
            //   if ((index + 1) % 9 === 0) {
            //     setTimeout(() => {
            //       this.appendAdsToPost(post, pageId);
            //     }, 2000);
            //   }
            // });
            let name;
            let urlslug;
            if (this.state.subCat !== undefined && this.state.subCat !== "") {
              name = `${category}-${this.props.params.slug}`;
              urlslug = `${category}/${this.props.params.slug}`;
            } else {
              name = `${category}`;
              urlslug = `${category}`;
            }
            this.setState({
              totalPages: resCat.data.pagecount,
              activePage: customPageNum,
              Posts: this.state.Posts.concat(paginationPosts),
              Loading: false,
              searchSlug: category,
              pageNumber: customPageNum + 1,
              TrendingVideoId: scriptId,
              TrendingVideoSrc: scriptSrc,
              category: category,
              subCat: this.props.params.slug,
              mainCategory: mainCat,
              pageId: pageId,
              listScript: StructuredDataListScript(
                resCat.data.data,
                urlslug,
                name,
                "Category Page"
              ),
            });
            this.StructuredDataBreadcrumbList();
            const self = this;
            const content = self.renderTopAd(119621);
            var div = document.createElement("span");
            div.innerHTML = content;
            $("#bannerAd_sas_119621_11111").html("");
            $("#bannerAd_sas_119621_11111").append(content);
            if (flagAds) {
              return;
            }
            const content2 = self.renderTopAd(119313);
            var div = document.createElement("span");
            div.innerHTML = content2;
            $("#bannerAd_sas_119313_11111").html("");
            $("#bannerAd_sas_119313_11111").append(content2);
            flagAds = true;
            appendLotameScript(this.state);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderTopAd(adId) {
    const pageId = this.state.pageId;
    const topAdFunction = `
    <script>sas.cmd.push(function() 
    {sas.call('std', {siteId:391880,pageId:${pageId},formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
    `;
    return topAdFunction;
  }

  loadSeries = async () => {
    this.setState({ LoadingSeries: true });
    const category = this.props.params.slug;
    await axios
      .get(`/get-special-pages?page_type=special&category=${category}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data === false) {
          this.setState({ hasSpecials: false });
        } else {
          this.setState({ hasSpecials: true });
        }
        this.setState({ LoadingSeries: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  do_load() {
    var scriptJW = document.createElement("script");
    scriptJW.src = this.state.TrendingVideoSrc;
    scriptJW.defer = true;
    document.body.appendChild(scriptJW);
  }

  render() {
    const {
      Loading,
      searchSlug,
      Posts,
      hasMore,
      category,
      subCat,
      listScript,
      breadcrumbsListScript,
    } = this.state;
    localStorage.setItem("initialPostsLoad", "true");
    localStorage.setItem("initialSlugLoad", "true");

    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbsListScript)}
          </script>
        </Helmet>
        <section id="read-section" className="read-section">
          <div className="container">
            {!Loading ? (
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-1">
                  <h1 data-toggle="tooltip" title={searchSlug}>
                    {category}
                    {typeof subCat !== "undefined" ? " - " + subCat : ""}
                  </h1>
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                {!Loading ? (
                  <InfiniteScroll
                    dataLength={Posts.length}
                    next={this.loadPosts}
                    hasMore={hasMore}
                    loader={
                      <div
                        className="col-md-12"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        <img
                          placeholder={loadingSVG}
                          src={loadingSVG}
                          className="img-fluid img-responsive  bottom-img-spinner img-sizing"
                          alt="alt"
                        />
                      </div>
                    }
                    endMessage={
                      <div className="col-md-12">
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      </div>
                    }
                  >
                    <GridComponent2
                      numPosts={9}
                      state={this.state}
                      columns={3}
                      pagination={false}
                      excerpt={true}
                      loadType="home"
                      pageNumber="1"
                      Posts={Posts}
                      Loading={this.state.Loading}
                      gridAds={true}
                      intervalo={4}
                    />
                    {/* {Posts.map((post, index) => {
                      return (
                        
                        // <div className="col-xl-4 col-lg-4 col-md-4 col-12 mb-4" key={post.ID}>
                        //     <GridComponent post={post} state={this.state}/>
                        // </div> 
                    )
                    } */}
                  </InfiniteScroll>
                ) : (
                  <>
                    <div
                      className="row mt-3 row-cols-1 row-cols-sm-2 row-cols-lg-3 g-lg-3 g-2"
                      data-masonry='{"percentPosition": true }'
                    >
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                      <GridArticleComponent />
                    </div>
                  </>
                )}
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <MailchimpFormComponent category={category} />
                {this.state.hasSpecials ? (
                  <>
                    <div className="top-adverties video-container">
                      <h4 className="side-heading">SPECIALS</h4>
                      <SpecialsComponent
                        section="network"
                        category={this.props.params.slug}
                      />
                    </div>
                  </>
                ) : (
                  ``
                )}

                <div className="top-adverties">
                  <div id="bannerAd_sas_119313_11111"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
            </div>
          </div>
        </section>

        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered"></div>
        </section>
      </>
    );
  }
}
export default withParams(CategoryTemporal);
