import React, { useState, useEffect } from "react";
import axios from "../apiConnections/axiosstaticpages";
import { Helmet } from "react-helmet";
import "../styles/termsconditions.css";
import { Html5Entities } from "html-entities";

const TermsConditionsPageComponent = () => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  // Lotame Stuff (can be refactored into separate functions)
  const createElementFromHTMLString = (htmlString) => {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    return div.firstChild;
  };

  const lotameLoad = () => {
    let path_name = window.location.pathname;
    let url = window.location.href;
    let article_tags = [];
    let article_cats = [];

    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${"corporate"}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${""}`],
        article_author: [`${""}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
        <script type="text/javascript">
          window.lotame_16314.cmd.push(function() {
            window.lotame_16314.page(${dataString});
          });
        </script>
      `;
    return lotameFunction;
  };

  const appendLotameScript = () => {
    // console.log("ejecuta el lotame script");
    const scriptLotameTag = createElementFromHTMLString(lotameLoad());
    scriptLotameTag.defer = true;
    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    document.getElementById("lotameTag").append(scriptLotameTag);
  };
  // Lotame Stuff

  useEffect(() => {
    const loadTerms = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/pages/?slug=terms-conditions&timestamp=${new Date().getTime()}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        );
        setTitle(response.data[0].title.rendered);
        setContent(response.data[0].content.rendered);
        appendLotameScript();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadTerms();
  }, []);

  return (
    <>
      <section id="read-section" className="read-section mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              {/* Removed unused h2 element */}
              {!loading && (
                <p
                  className="about-info"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditionsPageComponent;
