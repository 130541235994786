import LoadingPost from "../../images/loading.jpg";
// import NoPicture from "../../images/NoPicture.png";
import React, { Component } from "react";
// import SinglePostAuthors from "./MobileView/SinglePostAuthors";
import Pagination from "react-js-pagination";
import axios from "../../apiConnections/axios";
// import "./MobileView/mobileView.css";
// import '../CustomCss/SearchPage.css';
// import Img from 'react-cool-img';
import $ from "jquery";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import SasSideAdComponent from "./Ads/SasSideAdComponent";
// import {
//   isMobileOnly
// } from "react-device-detect";
// import ReactGA from 'react-ga';

// import MailchimpFormComponent from "./Mailchimp/MailchimpFormComponent";

import appleIcon from "../../images/icons/AuthorIcons/Apple.png";
import behanceIcon from "../../images/icons/AuthorIcons/Behance-2.png";
import deliciousIcon from "../../images/icons/AuthorIcons/Delicious.png";
import deviantArtIcon from "../../images/icons/AuthorIcons/DeviantArt-2.png";
import diggIcon from "../../images/icons/AuthorIcons/Digg.png";
import dribbbleIcon from "../../images/icons/AuthorIcons/Dribbble-2.png";
import facebookIcon from "../../images/icons/AuthorIcons/Facebook-2.png";
import flickrIcon from "../../images/icons/AuthorIcons/Flickr.png";
import githubIcon from "../../images/icons/AuthorIcons/Github.png";
import googleIcon from "../../images/icons/AuthorIcons/GooglePlus.png";
import twitterIcon from "../../images/icons/AuthorIcons/TWITTER.1.png";
import instaIcon from "../../images/icons/AuthorIcons/Instagram.png";
import linkedinIcon from "../../images/icons/AuthorIcons/LinkedIN.png";
import pinterestIcon from "../../images/icons/AuthorIcons/Pinterest.png";
import redditIcon from "../../images/icons/AuthorIcons/ReddIT.png";
import rssIcon from "../../images/icons/AuthorIcons/Rss.png";
import skypeIcon from "../../images/icons/AuthorIcons/skype.png";
import soundcloudIcon from "../../images/icons/AuthorIcons/SOUNDCLOUD.1.png";
import spotifyIcon from "../../images/icons/AuthorIcons/SPOTIFY.1.png";
import stumbleuponIcon from "../../images/icons/AuthorIcons/StumbleUpon.png";
import tumblrIcon from "../../images/icons/AuthorIcons/TUMBLR.1.png";
// import vimeoIcon from "../../images/icons/AuthorIcons/VIMEO.1.png";
import vineIcon from "../../images/icons/AuthorIcons/VINE.1.png";
import wordpressIcon from "../../images/icons/AuthorIcons/WORDPRESS.1.png";
import xingIcon from "../../images/icons/AuthorIcons/XING.1.png";
import yahooIcon from "../../images/icons/AuthorIcons/YAHOO.1.png";
import youtubeIcon from "../../images/icons/AuthorIcons/YOUTUBE.1.png";
import emailIcon from "../../images/icons/AuthorIcons/email.png";
import urlIcon from "../../images/icons/AuthorIcons/url.png";
import _ from "lodash";
import playbutton from "../../images/play-button.png";
import SpinnerImage from "../../images/ZZ5H.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import GridComponent2 from "../SharedComponents/GridComponent2";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class AuthorsSectionComponent extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      Loading: true,
      LoadingAuthor: true,
      LoadingRelated: true,
      Posts: [],
      activePage: 1,
      totalPages: 1,
      pageNumber: 1,
      randomIdBottomAd: 0,
      pageId: 1833561,
      siteId: 391880,
      itemscount: 9,
      mainPostId: "",
      mainPostCategory: "authors",
      mainPostCategorySlug: "",
      subCategories: [],
      LatestPosts: [],
      articleTags: [],
      author: "",
      articleAuthor: "",
      articleTitle: "",
      authorProfilePic: "",
      authorFirstName: "",
      authorLastName: "",
      authorBio: "",
      authorApple: "",
      authorBehance: "",
      authorDelicious: "",
      authorDeviantart: "",
      authorDigg: "",
      authorDribbble: "",
      authorEmail: "",
      authorFacebook: "",
      authorFlickr: "",
      authorGithub: "",
      authorGoogle: "",
      authorInstagram: "",
      authorLinkedin: "",
      authorPinterest: "",
      authorReddit: "",
      authorRss: "",
      authorSkype: "",
      authorSoundcloud: "",
      authorSpotify: "",
      authorStumbleupon: "",
      authorTumblr: "",
      authorTwitter: "",
      authorUrl: "",
      authorVimeoSquare: "",
      authorVine: "",
      authorWordpress: "",
      authorXing: "",
      authorYahoo: "",
      authorYoutube: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
      hasMore: true,
      mailchimpTags: [],
    };
  }

  componentDidMount() {
    this.setState({ author: this.props.params.slug });
    this.loadPosts(1);
    this.loadAuthor();
    // $('[data-toggle="tooltip"]').tooltip();
    window.scrollTo(0, 0);
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);

    // ReactGA.initialize('UA-3906388-4'); //UA-3906388-4
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  //   componentWillUnmount() {
  //     localStorage.setItem("numOfPosts", 0);
  //     window.location.reload();
  //   }

  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    // console.log("si se ejecuta la funcion")
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    // console.log(document.getElementById('lotameTag'));
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    // console.log("lotame load")
    let path_name = window.location.pathname;
    let url = window.location.href;
    // console.log(this.state.mainPostCategory)
    // let article_tags = [];
    let article_cats = [];
    // this.state.articleTags.forEach((a) => { article_tags.push(a) } );
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: [`${this.state.author}`],
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.author}`],
      },
    };
    let dataString = JSON.stringify(data);
    // console.log(dataString)
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }

  //   loadPosts = async (pageNumber) => {
  //     this.setState({ Loading: true });
  //     await axios.get(`/get-home-page-posts?page_no=${pageNumber}&timestamp=${new Date().getTime()}`, {
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json;charset=UTF-8',
  //       }
  //     })
  //       .then(res => {
  //         this.setState({ totalPages: res.data.pagecount, activePage: pageNumber, Posts: res.data.data, Loading: false });
  //         $('[data-toggle="tooltip"]').tooltip();
  //         $('[rel=tooltip]').tooltip({ trigger: "hover" });
  //         this.appendLotameScript();
  //           })
  //           .catch((error) => {
  //             console.log(error)
  //           })
  //   }

  loadLatestPosts = async (PostID) => {
    // console.log("carga los posts realacionados")
    // /get-post-by-category/?category=food
    await axios
      .get(
        `/get-releted-post-from-contextly/?PostId=${PostID}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({ LatestPosts: res.data.data });
        // console.log("Latest1: "+JSON.stringify(res.data.data));
        // this.setState({ totalPages: res.data.SinglePagecount});
        this.setState({ LoadingRelated: false });
        // $('[data-toggle="tooltip"]').tooltip();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadPosts = async (pageNumber) => {
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }
    var author = this.props.params.slug;
    // console.log(author);
    this.setState({ Loading: true });
    var apipath = `/get-posts-by-author?author=${author}&itemscount=${this.state.itemscount}&page_no=${pageNumber}`;
    await axios
      .get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({
          totalPages: res.data.pagecount,
          activePage: pageNumber,
          // Posts: paginationPosts,
          Posts: res.data.data,
          Loading: false,
          // randomIdBottomAd: randomid,
          // randomIdTopAd: randomid2,
        });
        // console.log(res.data.data[0].ID);

        if (pageNumber === 1) {
          this.loadLatestPosts(res.data.data[0].ID);
          const ads = "[{ id: 119619 },{ id: 119280 },{ id: 119621 }]";
          const pageId = this.state.pageId;
          // setTimeout(function () {
          //   var content = `<script>
          //   var pageid = ${pageId};
          //   var sas = sas || {};
          //   sas.cmd = sas.cmd || [];
          //   sas.cmd.push(function() {
          //   sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async : true });
          //       });
          //   sas.cmd.push(function () {
          //   sas.enableLazyLoading({
          //     fetchRatio: 0.1,
          //     mobileFetchRatio: 0.1,
          //     formats:  ${ads}
          //     });
          //   });
          //   sas.cmd.push(function() {
          //     sas.call("onecall", {
          //       siteId: 391880,
          //       pageId: ${pageId},
          //       formats: ${ads},
          //       target: ''
          //     });
          //   });
          //   sas.cmd.push(function() { sas.render("119313"); });
          //   sas.cmd.push(function() { sas.render("119621"); });
          //   sas.cmd.push(function() { sas.render("119280"); });
          //         </script>`;
          //   var div = document.createElement("span");
          //   div.innerHTML = content;

          //   $("#ads-rendered").html("");
          //   $("#ads-rendered").append(content);
          // }, 100);
          setTimeout(() => {
            this.appendLotameScript();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // loadPostsMobile = async (pageNumber) => {
  //     if (this.state.totalPages < this.state.pageNumber) {
  //       this.setState({ hasMore: false });
  //       return;
  //     }

  //     var category = this.props.match.params.cat;
  //     var subcategory = this.props.slug;
  //     var customPageNum= this.state.pageNumber;
  //     console.log("current Page:"+customPageNum);
  //     var apipath = `/get-posts-by-author?author=${this.state.author}&itemscount=${this.state.itemscount}&page_no=${this.state.pageNumber}`;
  //     await axios.get(`${apipath}`, {
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json;charset=UTF-8',
  //         }
  //     })
  //         .then(res => {
  //             if(this.state.pageNumber === 1){
  //               this.loadLatestPosts();
  //             }

  //         const paginationPosts = [].concat(res.data.data);
  //         paginationPosts.forEach((post, i) => {
  //           if( (i === 2) || (i === 5) || (i === 8) ){
  //             post.randomIdBottomAd = parseInt(Math.random() * 1000000000);
  //           }
  //         });
  //         const randomid = parseInt(Math.random() * 1000000000);
  //         const randomid2 = parseInt(Math.random() * 1000000000);
  //           // this.setState({
  //           //     totalPages: res.data.pagecount,
  //           //     activePage: pageNumber,
  //           //     Posts: paginationPosts,
  //           //     Loading: false,
  //           //     randomIdBottomAd: randomid,
  //           //     randomIdTopAd: randomid2,
  //           // });
  //           // this.state.Posts.concat(res.data.data)

  //             this.setState({
  //                 totalPages: res.data.pagecount,
  //                 activePage: customPageNum,
  //                 Posts: paginationPosts,
  //                 Loading: false,
  //                 searchSlug : category+'-'+subcategory,
  //                 pageNumber:customPageNum+1,
  //                 randomIdBottomAd: randomid,
  //                 randomIdTopAd: randomid2,
  //             });

  //             // setTimeout(function () {
  //             //   var content = `<script type="text/javascript">

  //             //   var sas = sas || {};
  //             //   sas.cmd = sas.cmd || [];
  //             //   sas.cmd.push(function() {
  //             //     sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true, inSequence:true  });
  //             //   });
  //             //   /*sas.cmd.push(function () {
  //             //     sas.enableLazyLoading({
  //             //       fetchRatio: 0.0001,
  //             //       mobileFetchRatio: 0.0001,
  //             //       formats: [
  //             //         { id: 119280 },
  //             //         { id: 119313 },
  //             //         { id: 119619 },
  //             //         { id: 119621 },
  //             //         { id: 119314 },
  //             //         { id: 119311 },
  //             //         { id: 119315 },
  //             //         { id: 119316 }
  //             //       ]
  //             //     });
  //             //   });*/
  //             //   sas.cmd.push(function() {
  //             //     sas.call("onecall", {
  //             //       siteId: 391880,
  //             //       pageId: 1743691,
  //             //       formats: [
  //             //         { id: 119280 },
  //             //         { id: 119313 },
  //             //         { id: 119619 },
  //             //         { id: 119621 },
  //             //         { id: 119314 },
  //             //         { id: 119311 },
  //             //         { id: 119315 },
  //             //         { id: 119316 }
  //             //       ],
  //             //       target: ''
  //             //     });
  //             //   });
  //             //   jQuery( document ).ready(function() {
  //             //   sas.cmd.push(function() { sas.render("119280"); });
  //             //   sas.cmd.push(function() { sas.render("119313"); });
  //             //   sas.cmd.push(function() { sas.render("119619"); });
  //             //   sas.cmd.push(function() { sas.render("119621"); });
  //             //   sas.cmd.push(function() { sas.render("119314"); });
  //             //   sas.cmd.push(function() { sas.render("119311"); });
  //             //   sas.cmd.push(function() { sas.render("119315"); });
  //             //   sas.cmd.push(function() { sas.render("119316"); });
  //             // });</script>`;
  //             //   var div = document.createElement("span");
  //             //   div.innerHTML = content;

  //             //   $("#ads-rendered").html("");
  //             //   $("#ads-rendered").append(content);
  //             // }, 100);

  //             if(isMobileOnly){
  //               const self = this;
  //               setTimeout(function () {
  //                 self.appendAdsToPosts(paginationPosts);
  //                 // self.loadAd();
  //               }, 3000);
  //             }
  //         })
  //         .catch((error) => {
  //             console.log(error)
  //         })

  // };

  loadPostsMobile = async (pageNumber) => {
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }

    var author = this.props.params.slug;
    // var category = this.props.params.cat;
    // var subcategory = this.props.slug;
    var customPageNum = this.state.pageNumber;
    // console.log("current Page:"+customPageNum);
    var apipath = `/get-posts-by-author?author=${author}&itemscount=${this.state.itemscount}&page_no=${this.state.pageNumber}`;
    await axios
      .get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        // console.log(this.state.pageNumber);
        if (this.state.pageNumber === 1) {
          this.loadLatestPosts(res.data.data.ID);
          const ads = "[{ id: 119314 },{ id: 119280 }]";
          const pageId = this.state.pageId;
          // setTimeout(function () {
          //   var content = `<script>
          //   var pageid = ${pageId};
          //   var sas = sas || {};
          //   sas.cmd = sas.cmd || [];
          //   sas.cmd.push(function() {
          //   sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async : true });
          //       });
          //   sas.cmd.push(function () {
          //   sas.enableLazyLoading({
          //     fetchRatio: 0.1,
          //     mobileFetchRatio: 0.1,
          //     formats:  ${ads}
          //     });
          //   });
          //   sas.cmd.push(function() {
          //     sas.call("onecall", {
          //       siteId: 391880,
          //       pageId: ${pageId},
          //       formats: ${ads},
          //       target: ''
          //     });
          //   });
          //   sas.cmd.push(function() { sas.render("119314"); });
          //   sas.cmd.push(function() { sas.render("119280"); });
          //         </script>`;
          //   var div = document.createElement("span");
          //   div.innerHTML = content;

          //   $("#ads-rendered").html("");
          //   $("#ads-rendered").append(content);
          // }, 100);
          setTimeout(() => {
            this.appendLotameScript();
          }, 1000);
        }
        // console.log(res.data)
        // console.log(res.data.data)

        const paginationPosts = [].concat(res.data.data);
        paginationPosts.forEach((post, i) => {
          post.randomIdBottomAd = parseInt(Math.random() * 1000000000);
        });
        const randomid = parseInt(Math.random() * 1000000000);
        const randomid2 = parseInt(Math.random() * 1000000000);
        // this.setState({
        //     totalPages: res.data.pagecount,
        //     activePage: pageNumber,
        //     Posts: paginationPosts,
        //     Loading: false,
        //     randomIdBottomAd: randomid,
        //     randomIdTopAd: randomid2,
        // });
        // console.log(paginationPosts)

        this.setState({
          totalPages: res.data.pagecount,
          activePage: customPageNum,
          Posts: this.state.Posts.concat(paginationPosts),
          Loading: false,
          // searchSlug: category + "-" + subcategory,
          pageNumber: customPageNum + 1,
          randomIdBottomAd: randomid,
          randomIdTopAd: randomid2,
        });

        // console.log(this.state.Posts)

        // if(isMobileOnly){
        //   const self = this;
        //   setTimeout(function () {
        //     self.appendAdsToPosts(paginationPosts);
        //     // self.loadAd();
        //   }, 3000);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  appendAdsToPosts(posts) {
    const self = this;
    this.state.Posts.forEach((post, i) => {
      let idx = i + 1;
      if (!post.adAppended && idx % 3 === 0) {
        self.loadAd(i);
        // this.appendAd(post.randomIdBottomAd);
        post.adAppended = true;
      }
    });
  }

  loadAd(index) {
    var rndId = parseInt(Math.random() * 1000000);

    var art = document.createElement("div");
    $(art).addClass("article");

    var artBanner = document.createElement("div");
    artBanner.id = "bannerAd_" + rndId;
    $(`#articles_${index}`).append(artBanner);

    var option = "{ resetTimestamp: true }";
    var smartTags = document.createElement("script");
    var code =
      "sas.cmd.push(function() {sas.call('std', {siteId:391880,pageId:1833561,formatId:119280,tagId:'bannerAd_" +
      rndId +
      "'}, " +
      option +
      ");});";
    $(smartTags).text(code);
    $(`#articles_${index}`).append(smartTags);
  }

  getAdFunction(pageId) {
    const adFunction = `
        var sas = sas || {};
        sas.cmd = sas.cmd || [];
        sas.cmd.push(function() {
            sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
        });
        sas.cmd.push(function() {
            sas.call("onecall", {
                siteId: ${this.state.siteId},
                pageId: ${pageId},
                formats: [{ id: 119280 }],
                target: ''
            });
        });
    `;
    // console.log(adFunction);
    return adFunction;
  }

  appendAd(divId) {
    const format = "119280";
    const siteId = this.state.siteId;
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;
    var art = document.createElement("div");
    // $(art).addClass(`${type}-adverties`);
    // Create a container in the article with the SMART tag
    var smartDiv = document.createElement("div");
    smartDiv.id = rndId;
    var smartTag = document.createElement("script");
    $(smartTag).text(
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${this.state.pageId},formatId:${format},tagId:${rndId}});});`
    );
    art.appendChild(smartDiv);
    art.appendChild(smartTag);
    $("#" + divId).append(art);
    // currentAdsIds.push(rndId);

    var refreshContentSc = document.createElement("script");
    $(refreshContentSc).text(
      "localStorage.setItem('startDate" +
        rndId +
        "', 'start'); localStorage.setItem('temp" +
        rndId +
        "', '" +
        rndId +
        "'); localStorage.setItem('tempcount" +
        rndId +
        "', 0);   setInterval(function(){var countsAds=0; var top_of_element = $('#" +
        rndId +
        "').offset().top; var bottom_of_element = $('#" +
        rndId +
        "').offset().top + $('#" +
        rndId +
        "').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate" +
        rndId +
        "'); if(lastsyncdata=='start'){ localStorage.setItem('startDate" +
        rndId +
        "', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate" +
        rndId +
        "')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount" +
        rndId +
        "');  var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp" +
        rndId +
        "');   if(loopCount<5 && Diffseconds>30){ sas.cmd.push(function() {sas.refresh(" +
        rndId +
        ");}); localStorage.setItem('tempcount" +
        rndId +
        "', (Number(localStorage.getItem('tempcount" +
        rndId +
        "'))+1)); localStorage.setItem('startDate" +
        rndId +
        "', new Date()); }}},1000)"
    );
    $("#" + divId).append(refreshContentSc);
  }

  loadAuthor = async () => {
    this.setState({ Loading: true });
    var author = this.props.params.slug;
    var apipath = `/get-author-data?author=${author}`;
    await axios
      .get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        // console.log(res.data.data[0])
        this.setState({
          LoadingAuthor: false,
          authorProfilePic: res.data.data[0].image,
          authorFirstName: res.data.data[0].firstname,
          authorLastName: res.data.data[0].lastname,
          authorBio: res.data.data[0].bio,
          authorApple: res.data.data[0].apple,
          authorBehance: res.data.data[0].behance,
          authorDelicious: res.data.data[0].delicious,
          authorDeviantart: res.data.data[0].deviantart,
          authorDigg: res.data.data[0].digg,
          authorDribbble: res.data.data[0].dribbble,
          authorEmail: res.data.data[0].email,
          authorFacebook: res.data.data[0].facebook,
          authorFlickr: res.data.data[0].flickr,
          authorGithub: res.data.data[0].github,
          authorGoogle: res.data.data[0].google,
          authorInstagram: res.data.data[0].instagram,
          authorLinkedin: res.data.data[0].linkedin,
          authorPinterest: res.data.data[0].pinterest,
          authorReddit: res.data.data[0].reddit,
          authorRss: res.data.data[0].rss,
          authorSkype: res.data.data[0].skype,
          authorSoundcloud: res.data.data[0].soundcloud,
          authorSpotify: res.data.data[0].spotify,
          authorStumbleupon: res.data.data[0].stumbleupon,
          authorTumblr: res.data.data[0].tumblr,
          authorTwitter: res.data.data[0].twitter,
          authorUrl: res.data.data[0].url,
          // eslint-disable-next-line no-undef
          // authorVimeoSquare: res.data.data[0].vimeo-square,
          authorVine: res.data.data[0].vine,
          authorWordpress: res.data.data[0].wordpress,
          authorXing: res.data.data[0].xing,
          authorYahoo: res.data.data[0].yahoo,
          authorYoutube: res.data.data[0].youtube,
          mailchimpTags: ["authors", this.state.author],
        });
        // console.log(this.state.mailchimpTags);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ Loading: true, pageNumber: pageNumber });
    this.loadPosts(pageNumber);
  }

  render() {
    let Bsize = "";
    const {
      Loading,
      Posts,
      randomIdBottomAd,
      LatestPosts,
      authorProfilePic,
      authorBio,
      authorFirstName,
      authorLastName,
      authorApple,
      authorBehance,
      authorDelicious,
      authorDeviantart,
      authorDigg,
      authorDribbble,
      authorEmail,
      authorFacebook,
      authorFlickr,
      authorGithub,
      authorGoogle,
      authorInstagram,
      authorLinkedin,
      authorPinterest,
      authorReddit,
      authorRss,
      authorSkype,
      authorSoundcloud,
      authorSpotify,
      authorStumbleupon,
      authorTumblr,
      authorTwitter,
      authorUrl,
      authorVimeoSquare,
      authorVine,
      authorWordpress,
      authorXing,
      authorYahoo,
      authorYoutube,
      itemscount,
      LoadingAuthor,
      LoadingRelated,
      hasMore,
    } = this.state;
    let counter = 0;
    return (
      <>
        <section id="read-section" className="read-section">
          <div className="container">
            {/* <div className="top-mobile-ad">
            {
              (isMobileOnly) ? (<SasSideAdComponent sasId={"119314"}/> ) : ('')
            }
          </div> */}
            {/* <div className='row'><SasSideAdComponent sasId={"119619"}/></div> */}
            {authorProfilePic !== "" && authorBio !== "" ? (
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="author-section">
                    {!LoadingAuthor ? (
                      Posts.slice(0, 1).map((post) => {
                        const {
                          ID,
                          author_name,
                          post_category,
                          short_description,
                          post_slug,
                          post_image,
                          post_title,
                          publish_date,
                          imgs,
                        } = post;
                        return (
                          <div key={ID}>
                            <img
                              placeholder={authorProfilePic}
                              src={authorProfilePic}
                              className="img-fluid  w-100 h-auto main-read-img-authors img-sizing"
                              alt="Loading"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <div className="main-img-box">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div>
                                {/* <img
                                  placeholder={NoPicture}
                                  src={NoPicture}
                                  className="img-fluid  w-100 h-auto main-read-img-authors"
                                  alt="Loading"
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 author-bio">
                  <div
                    className="side-img-box col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    align
                  >
                    <h4 className="side-heading">
                      {authorFirstName} {authorLastName}
                    </h4>
                    <div className="main-box-content">
                      <p>{authorBio}</p>
                    </div>
                    <div class="col icon-images-50">
                      {/* <a target="_blank" href="${data.acf.social_facebook}" style="${data.acf.social_facebook !== "" ? 'display:initial' : 'display:none'}"><img src="${facebookIcon}"></a>
                    <a target="_blank" href="${data.acf.social_instagram}" style="${data.acf.social_instagram !== "" ? 'display:initial' : 'display:none'}"><img src="${instaIcon}"></a>
                    <a target="_blank" href="${data.acf.social_youtube}" style="${data.acf.social_youtube !== "" ? 'display:initial' : 'display:none'}"><img src="${youtubeIcon}"></a>
                    <a target="_blank" href="${data.acf.social_twitter}" style="${data.acf.social_twitter !== "" ? 'display:initial' : 'display:none'}"><img src="${linkedinIcon}"></a>
                    <a target="_blank" href="${data.acf.social_linkedin}" style="${data.acf.social_linkedin !== "" ? 'display:initial' : 'display:none'}"><img src="${twitterIcon}"></a>
                    <a target="_blank" href="${data.acf.social_tiktok}" style="${data.acf.social_tiktok !== "" ? 'display:initial' : 'display:none'}"><img src="${tiktokIcon}"></a> */}
                      <a
                        target="_blank"
                        href={authorUrl}
                        rel="noreferrer"
                        style={
                          authorUrl !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Site"
                          alt="Site-logo"
                          src={urlIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorApple}
                        rel="noreferrer"
                        style={
                          authorApple !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Apple"
                          alt="Apple-logo"
                          src={appleIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorBehance}
                        rel="noreferrer"
                        style={
                          authorBehance !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Behance"
                          alt="Behance-logo"
                          src={behanceIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorDelicious}
                        rel="noreferrer"
                        style={
                          authorDelicious !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Delicious"
                          alt="Delicious-logo"
                          src={deliciousIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorDeviantart}
                        rel="noreferrer"
                        style={
                          authorDeviantart !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Deviantart"
                          alt="Deviantart-logo"
                          src={deviantArtIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorDigg}
                        rel="noreferrer"
                        style={
                          authorDigg !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Digg"
                          alt="Digg-logo"
                          src={diggIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorDribbble}
                        rel="noreferrer"
                        style={
                          authorDribbble !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Dribbble"
                          alt="Dribbble-logo"
                          src={dribbbleIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={"mailto:" + authorEmail}
                        rel="noreferrer"
                        style={
                          authorEmail !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Email"
                          alt="Email-logo"
                          src={emailIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorFacebook}
                        rel="noreferrer"
                        style={
                          authorFacebook !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Facebook"
                          alt="Facebook-logo"
                          src={facebookIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorFlickr}
                        rel="noreferrer"
                        style={
                          authorFlickr !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Flickr"
                          alt="Flickr-logo"
                          src={flickrIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorGithub}
                        rel="noreferrer"
                        style={
                          authorGithub !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Github"
                          alt="Github-logo"
                          src={githubIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorGoogle}
                        rel="noreferrer"
                        style={
                          authorGoogle !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Google"
                          alt="Google-logo"
                          src={googleIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorInstagram}
                        rel="noreferrer"
                        style={
                          authorInstagram !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Instagram"
                          alt="Instagram-logo"
                          src={instaIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorLinkedin}
                        rel="noreferrer"
                        style={
                          authorLinkedin !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Linkedin"
                          alt="Linkedin-logo"
                          src={linkedinIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorPinterest}
                        rel="noreferrer"
                        style={
                          authorPinterest !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Pinterest"
                          alt="Pinterest-logo"
                          src={pinterestIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorReddit}
                        rel="noreferrer"
                        style={
                          authorReddit !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          alt="Reddit-logo"
                          title="Reddit"
                          src={redditIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorRss}
                        rel="noreferrer"
                        style={
                          authorRss !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          alt="Rss-logo"
                          title="Rss"
                          src={rssIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorSkype}
                        rel="noreferrer"
                        style={
                          authorSkype !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Skype"
                          alt="Skype-logo"
                          src={skypeIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorSoundcloud}
                        rel="noreferrer"
                        style={
                          authorSoundcloud !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Soundcloud"
                          alt="Soundcloud-logo"
                          src={soundcloudIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorSpotify}
                        rel="noreferrer"
                        style={
                          authorSpotify !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Spotify"
                          alt="Spotify-logo"
                          src={spotifyIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorStumbleupon}
                        rel="noreferrer"
                        style={
                          authorStumbleupon !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Stumbleupon"
                          alt="Stumbleupon-logo"
                          src={stumbleuponIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorTumblr}
                        rel="noreferrer"
                        style={
                          authorTumblr !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Tumblr"
                          alt="Tumblr-logo"
                          src={tumblrIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorTwitter}
                        rel="noreferrer"
                        style={
                          authorTwitter !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Twitter"
                          alt="Twitter-logo"
                          src={twitterIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorVine}
                        rel="noreferrer"
                        style={
                          authorVine !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Vine"
                          alt="Vine-logo"
                          src={vineIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorWordpress}
                        rel="noreferrer"
                        style={
                          authorWordpress !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Wordpress"
                          alt="Wordpress-logo"
                          src={wordpressIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorXing}
                        rel="noreferrer"
                        style={
                          authorXing !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Xing"
                          alt="Xing-logo"
                          src={xingIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorYahoo}
                        rel="noreferrer"
                        style={
                          authorYahoo !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Yahoo"
                          alt="Yahoo-logo"
                          src={yahooIcon}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={authorYoutube}
                        rel="noreferrer"
                        style={
                          authorYoutube !== ""
                            ? { display: `initial` }
                            : { display: `none` }
                        }
                      >
                        <img
                          data-toggle="tooltip"
                          title="Youtube"
                          alt="Youtube-logo"
                          src={youtubeIcon}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

              </div> */}
              </div>
            ) : (
              <></>
            )}

            <div className="row" style={{ marginTop: `10px` }}>
              <div className={this.state.matches ? "col-8" : "col"}>
                <div className="row">
                  <h1 className="next-super-heading non-border mb-4 mt-double">
                    {authorFirstName} {authorLastName} ARTICLES
                  </h1>

                  {Posts && Posts != null && this.state.matches ? (
                    <GridComponent2
                      itemsCount={6}
                      state={this.state}
                      columns={3}
                      pagination={false}
                      excerpt={true}
                      pageNumber="1"
                      Posts={Posts}
                      Loading={Loading}
                      gridAds={false}
                      clips={false}
                      seriesSpecials={true}
                    />
                  ) : (
                    <InfiniteScroll
                      dataLength={Posts.length}
                      next={this.loadPostsMobile}
                      hasMore={hasMore}
                      className="row"
                      loader={
                        <div
                          className="col-md-12"
                          style={{ textAlign: "center", padding: "20px" }}
                        >
                          <img
                            placeholder={SpinnerImage}
                            src={SpinnerImage}
                            cache
                            className="img-fluid img-responsive  bottom-img-spinner img-sizing"
                            alt="alt"
                          />
                        </div>
                      }
                      endMessage={
                        <div className="col-md-12">
                          <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        </div>
                      }
                    >
                      <div className="category-mobile-list">
                        {Posts.map((post, index) => {
                          const idx = (index + 1) % 10;
                          // console.log(idx);
                          const {
                            ID,
                            post_category,
                            post_image,
                            post_title,
                            post_slug,
                            publish_date,
                            post_type,
                            imgs,
                          } = post;
                          return (
                            <>
                              {/* {idx !== 1 && (
                            <div key={ID} id={`articles_${index}`}>
                              <SinglePost data={post} postKey={idx + new Date().valueOf()} />
                            </div>
                          )}
                          {(idx === 0 || idx === 4 || idx === 7) && (
                            <div className="mobile_ad ">
                              <div className='row'><SasSideAdComponent sasId={"119280"}/></div>
                            </div>
                          )} */}
                              {/* <div key={ID} id={`articles_${index}`}>
                                <SinglePostAuthors
                                  data={post}
                                  postKey={idx + new Date().valueOf()}
                                />
                              </div> */}
                            </>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  )}

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 center">
                    {/* {(Posts!==null && !isMobileOnly) ? (
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={1}
                                    totalItemsCount={this.state.totalPages}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    innerClass="pagination watch-page-pagination"
                                    hideFirstLastPages
                                    prevPageText="<"
                                    nextPageText=">"
                                />
                            ) : (``)} */}
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={1}
                      totalItemsCount={this.state.totalPages}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                      innerClass="pagination watch-page-pagination"
                      hideFirstLastPages
                      prevPageText="<"
                      nextPageText=">"
                    />
                  </div>
                </div>
              </div>

              <div className="col">
                <h4 className="side-heading mb-2 mt-double">
                  {LatestPosts.length >= 1 ? `RELATED ARTICLES` : ``}
                </h4>
                <div className="row">
                  <GridComponent2
                    itemsCount={3}
                    state={this.state}
                    columns={1}
                    pagination={false}
                    excerpt={true}
                    pageNumber="1"
                    Posts={LatestPosts}
                    Loading={LoadingRelated}
                    gridAds={false}
                    clips={false}
                    seriesSpecials={true}
                  />
                  {/* {!LoadingRelated ? (
                    LatestPosts.slice(0, 3).map((post) => {
                      const {
                        ID,
                        post_category,
                        post_image,
                        post_title,
                        post_slug,
                        publish_date,
                      } = post;
                      {
                        post_slug !== Posts.post_slug ? counter++ : <></>;
                      }
                      return post_slug !== Posts.post_slug ? (
                        <a href={`${post_slug}`} key={ID}>
                          <div className="side-box-articles" key={ID}>
                            <div className="row clearfix mb-3">
                              <div className="col-xl-12 col-lg-12 col-md-12 col-5 pr-0">
                                <div className="side-read-img">
                                  <img
                                    placeholder={LoadingPost}
                                    src={post_image.custom_image_url}
                                    loading="lazy"
                                    className="img-fluid img-responsive side-read-img-pic"
                                    alt={post_image.image_alt}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-12 col-lg-12 col-md-12 col-7">
                                <div className="sideRead-Content">
                                  <div className="row">
                                    <div
                                      className={`col-xl-10 col-lg-10 col-md-12 col-sm-12 pl-1`}
                                    >
                                      <div className="side-read-heading ml-2">
                                        <span
                                          className="side-read-category"
                                          data-toggle="tooltip"
                                          title={post_title}
                                        >
                                          {post_title !== null
                                            ? post_title.length > 70
                                              ? post_title.substr(0, 70) + `...`
                                              : post_title
                                            : ``}
                                        </span>
                                        <>
                                          <span className="side-read-heading-span">
                                            {publish_date}
                                          </span>
                                        </>
                                      </div>
                                      <div
                                        className="side-box-content ml-2"
                                        data-toggle="tooltip"
                                        title={
                                          post_category[0] &&
                                          post_category[0].cat_name !== null
                                            ? post_category[0].cat_name
                                            : ``
                                        }
                                      >
                                        {post_category[0] &&
                                        post_category[0].cat_name !== null
                                          ? post_category[0].cat_name.length >
                                            14
                                            ? post_category[0].cat_name.substr(
                                                0,
                                                14
                                              ) + `...`
                                            : post_category[0].cat_name
                                          : ``}
                                      </div>
                                      <>
                                        <div
                                          className="side-box-content ml-2"
                                          style={{ clear: "left" }}
                                        >
                                          <span
                                            className="side-read-heading-span"
                                            style={{ "margin-left": "0" }}
                                          >
                                            {publish_date}
                                          </span>
                                        </div>
                                        <div
                                          className="side-box-content ml-2 mt-2"
                                          style={{ clear: "left" }}
                                        >
                                          <span
                                            className="side-read-heading-span"
                                            style={{ "margin-left": "0" }}
                                          >
                                            READ MORE{" "}
                                            <i className="fa fa-arrow-right"></i>
                                          </span>
                                        </div>
                                      </>
                                    </div>

                                    <div
                                      className={`col-xl-12 col-lg-12 col-md-12 pl-0 col-2 `}
                                    >
                                      <div className="side-box-footer">
                                        <>
                                          READ MORE{" "}
                                          <i className="fa fa-arrow-right"></i>
                                        </>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      ) : (
                        <></>
                      );
                    })
                  ) : (
                    <>
                      <div></div>
                    </>
                  )} */}
                </div>
                {/* <div className="row">
                  <MailchimpFormComponent category={this.state.mailchimpTags} />
                </div> */}
                {/* {!isMobileOnly ? (
                  <div className='row'><SasSideAdComponent sasId={"119280"}/></div>
                ) : (
                  <></>
                )} */}
                {/* <div className="side-adverties ">
                    <div id={this.state.randomIdTopAd}> </div>
                </div> */}
              </div>
            </div>
            {/* <div className='row'><SasSideAdComponent sasId={"119621"}/></div> */}
          </div>
        </section>
        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered">
            {/* dangerouslySetInnerHTML={{ __html: content }}> */}
          </div>
        </section>
      </>
    );
  }
}
export default withParams(AuthorsSectionComponent);
