import React, { useState, useEffect } from "react";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import "../../NewCss/lgbtPlaceholder.css";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import VideoComponent from "../VideoComponent/VideoComponent.js";
import SpecialsCarousel from "../Specials/SpecialsCarousel.js";
import SubscribeComponent from "../SubscribeComponent/SubscribeComponent.js";
import ArticlesSectionQueer from "../Articles/ArticlesComponent.js";
import scrollArrowDown from "../../images/scroll-arrow-up.svg";
import scrollArrowUp from "../../images/scroll-arrow-down.svg";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;

const QueerHomeComponent = () => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [posts, setPosts] = useState("");
  const [breadcrumbsListScript, setbreadcrumbsListScript] = useState("");
  const [categoriesColors, setCategoriesColors] = useState("");
  const [trendingVideoId, setTrendingVideoId] = useState("");
  const [trendingVideoSrc, setTrendingVideoSrc] = useState("");
  const [dataItemList, setDataItemList] = useState("");
  const [sasSetup, setSasSetup] = useState(false);
  const [sasSetupContent, setSasSetupContent] = useState("");

  useEffect(() => {
    const asignColors = () => {
      if (posts === "") {
        return;
      }
      const categories = new Set();
      const results = [];
      const colors = ["orange", "blue", "pink", "yellow", "green", "red"];
      posts.slice(0, posts.length).map((post, i) => {
        return categories.add(post.post_category[0].cat_name);
      });
      const catArray = Array.from(categories);
      catArray.slice(0, catArray.length).map((category, i) => {
        return results.push({
          category: category,
          color: colors[i],
        });
      });
      setCategoriesColors(results);
    };

    asignColors();
  }, [posts]);

  const getScriptId = (srcString) => {
    const scriptId = srcString
      .substring(srcString.indexOf("rs/") + 3, srcString.lastIndexOf(".js"))
      .replaceAll(/-/gi, "_");
    return scriptId;
  };

  const getVideoScript = (htmlString) => {
    // Create a temporary div element to parse the HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // Find the script tag
    const scriptTag = tempDiv.querySelector(
      "script[src^='https://cdn.jwplayer.com/players/']"
    );

    // Extract the src attribute value
    const srcValue = scriptTag ? scriptTag.getAttribute("src") : null;

    return srcValue;
  };

  useEffect(() => {
    const appendVideoScript = (TrendingVideoSrc) => {
      var scriptJW = document.createElement("script");
      scriptJW.src = TrendingVideoSrc;
      scriptJW.defer = true;
      const videoDiv = document.querySelector('[id^="botr_"]');
      console.log(videoDiv);
      document.body.appendChild(scriptJW);
    };

    appendVideoScript(trendingVideoSrc);
  }, [trendingVideoSrc]);

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const customPageNum = 1;
      const itemscount = 9;
      const category = "queer";
      try {
        const response = await axios.get(
          `/get-post-by-category?page_no=${customPageNum}&itemscount=${itemscount}&category=${category}&timestamp=${new Date().getTime()}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        );
        setPosts(response.data.data);
        setDataItemList(
          StructuredDataListScript(
            response.data.data,
            "queer",
            "Latest Articles",
            "Our latest Articles"
          )
        );
        StructuredDataBreadcrumbList();
        const extractedSrc = getVideoScript(response.data.data[0].post_content);
        const scriptId = getScriptId(extractedSrc);
        setTrendingVideoId(scriptId);
        setTrendingVideoSrc(extractedSrc);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  function scrollDownOnHover(componentId) {
    const button = document.getElementById("scrollDownButton");
    const component = document.getElementById(componentId);

    let isHovering = false; // Flag to track hover state

    button.addEventListener("mouseover", () => {
      isHovering = true;

      const initialScrollPosition = component.scrollTop;

      // Smooth scrolling using requestAnimationFrame
      let scrollPosition = initialScrollPosition;
      const targetPosition = component.scrollHeight;

      function animateScroll() {
        scrollPosition += (targetPosition - scrollPosition) / 80;
        component.scrollTop = scrollPosition;

        if (scrollPosition < targetPosition && isHovering) {
          requestAnimationFrame(animateScroll);
        }
      }

      requestAnimationFrame(animateScroll);
    });

    button.addEventListener("mouseout", () => {
      isHovering = false;
    });
  }

  function scrollUpOnHover(componentId) {
    const button = document.getElementById("scrollUpButton");
    const component = document.getElementById(componentId);

    let isHovering = false;

    button.addEventListener("mouseover", () => {
      isHovering = true;

      const initialScrollPosition = component.scrollTop;

      // Smooth scrolling using requestAnimationFrame
      let scrollPosition = initialScrollPosition;
      const targetPosition = 0; // Scroll to the top of the component

      function animateScroll() {
        scrollPosition -= (scrollPosition - targetPosition) / 35; // Adjust scroll position gradually (upwards)
        component.scrollTop = scrollPosition;

        if (scrollPosition > targetPosition && isHovering) {
          requestAnimationFrame(animateScroll);
        }
      }

      requestAnimationFrame(animateScroll);
    });

    button.addEventListener("mouseout", () => {
      isHovering = false;
    });
  }

  function StructuredDataBreadcrumbList() {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://latv.com/",
            name: "Home",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `https://latv.com/queer/`,
            name: "queer",
          },
        },
      ],
    };
    setbreadcrumbsListScript(structuredData);
  }

  return (
    <>
      <Helmet>
        {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script> */}
        <script type="application/ld+json">
          {JSON.stringify(dataItemList)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsListScript)}
        </script>
      </Helmet>
      <div>
        <div className="container queer px-xl-0 px-lg-0 px-md-3 px-sm-3 px-3 ">
          <div className="row">
            {!loading && categoriesColors.length > 0 ? (
              <>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-xl-0 mb-lg-0 mb-md-2 mb-sm-2 mb-2 first-post">
                  <div
                    className={
                      "row image-container full-border-" +
                      categoriesColors.find(
                        (cat) =>
                          cat.category === posts[0].post_category[0].cat_name
                      )?.color
                    }
                  >
                    {trendingVideoId ? (
                      <div id={`botr_${trendingVideoId}_div`}></div>
                    ) : (
                      <div
                        className="row image-container"
                        style={{ padding: 0 }}
                      >
                        <div style={{ padding: 0 }}>
                          <img
                            className="img-fluid w-100 h-auto img-sizing"
                            srcSet={posts[0].imgs
                              .toReversed()
                              .map((image, index) => {
                                if (image[1] === undefined) {
                                  return [];
                                }
                                const Srcset = [];
                                const Zize = image[1].toString();
                                const link = image[0].toString();
                                Srcset.push(link + " " + Zize + "w");
                                return Srcset;
                              })}
                            src={posts[0].post_image.full_image_url}
                            alt={posts[0].post_image.image_alt}
                            width="1000px"
                            height="563px"
                          />
                        </div>
                      </div>
                    )}
                    <div className="image-text">
                      <p>
                        <a
                          href="#"
                          className={
                            categoriesColors.find(
                              (cat) =>
                                cat.category ===
                                posts[0].post_category[0].cat_name
                            )?.color + " category"
                          }
                        >
                          {posts[0].post_category[0].cat_name}
                        </a>
                      </p>

                      <a className="lh-initial">
                        <h2
                          className={
                            "line-clamp main-post-title border-" +
                            categoriesColors.find(
                              (cat) =>
                                cat.category ===
                                posts[0].post_category[0].cat_name
                            )?.color
                          }
                          dangerouslySetInnerHTML={{
                            __html:
                              posts[0].post_title !== null
                                ? posts[0].post_title.length > 70
                                  ? posts[0].post_title.substr(0, 70) + `...`
                                  : posts[0].post_title
                                : ``,
                          }}
                        ></h2>
                      </a>
                    </div>
                  </div>
                  <div className="bottom-content black-text line-clamp-description mobile mt-2">
                    <p className="queer">{posts[0].short_description}</p>
                  </div>
                  <Link to={"/"} className="mobile">
                    <div
                      className="main-box-footer pink-color mt-2 mb-2"
                      style={{ justifyContent: "flex-end" }}
                    >
                      READ MORE{" "}
                      <i className="fa fa-arrow-right d-none d-md-inline "></i>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                  style={{ position: "relative" }}
                >
                  <div className="row d-xl-block d-lg-blok d-md-none d-sm-none d-none">
                    <img
                      className="desktop"
                      src={scrollArrowDown}
                      alt="scroll-arrow"
                      style={{
                        height: "30px",
                        alignContent: "center",
                        position: "relative",
                        zIndex: "1",
                        marginBottom: "-35px",
                        paddingTop: "5px",
                      }}
                      id="scrollUpButton"
                    />
                  </div>
                  <div className="row side-scroll" id="sideScrollSection">
                    {posts.slice(1, 9).map((post, index) => {
                      const {
                        ID,
                        post_image,
                        post_title,
                        post_slug,
                        post_type,
                        imgs,
                        short_description,
                      } = post;
                      if (post_type === "post") {
                        return (
                          <div
                            className="col-xl-12 col-lg-12 col-md-5 col-sm-5 col-9 p-xl-0 p-lg-0 p-md-2 p-sm-2 p-2"
                            style={{ marginBottom: "1px", padding: "0" }}
                          >
                            <div
                              className={
                                "square-duo full-border-" +
                                categoriesColors.find(
                                  (cat) =>
                                    cat.category ===
                                    post.post_category[0].cat_name
                                )?.color
                              }
                              style={{ margin: "auto", position: "relative" }}
                            >
                              <Link to={`/queer/${post_slug}/`}>
                                <div>
                                  <img
                                    sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                    srcSet={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset;
                                      })}
                                    src={post_image.medium_image_url}
                                    // cache
                                    className="img-fluid img-responsive w-100 img-sizing"
                                    alt="alt"
                                    width="1000px"
                                    height="563px"
                                  />
                                  <div className="image-text-second">
                                    <p>
                                      <a
                                        href="#"
                                        className={
                                          categoriesColors.find(
                                            (cat) =>
                                              cat.category ===
                                              post.post_category[0].cat_name
                                          )?.color + " slide-category"
                                        }
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p>

                                    <a className="lh-initial">
                                      <h2
                                        className={
                                          "line-clamp second-post-title border-" +
                                          categoriesColors.find(
                                            (cat) =>
                                              cat.category ===
                                              post.post_category[0].cat_name
                                          )?.color
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            post_title !== null
                                              ? post_title.length > 70
                                                ? post_title.substr(0, 70) +
                                                  `...`
                                                : post_title
                                              : ``,
                                        }}
                                      ></h2>
                                    </a>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      } else if (post_type === "watch") {
                        return (
                          <div
                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                            style={{ marginBottom: `1px` }}
                          >
                            <div className="square-duo">
                              <a href={`../watch/${post_slug}/`}>
                                <div className="">
                                  <div
                                    className="side-video-thumbnail"
                                    style={{ maxWidth: `92%` }}
                                  ></div>
                                  <div className="bottomRead-Content">
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html: post_title,
                                        }}
                                      ></h5>
                                    </div>
                                  </div>
                                  <div className="bottom-content black-text line-clamp">
                                    <p className="queer">{short_description}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div
                    className="row d-xl-block d-lg-blok d-md-none d-sm-none d-none"
                    style={{ zIndex: "1" }}
                  >
                    <img
                      className="desktop"
                      src={scrollArrowUp}
                      alt="scroll-arrow"
                      style={{
                        height: "30px",
                        alignContent: "center",
                        position: "relative",
                        zIndex: "1",
                        marginTop: "-35px",
                        paddingBottom: "5px",
                      }}
                      id="scrollDownButton"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <section id="main" className="container-xl">
                  <div className="row mt-5">
                    <div className="col-md-8 col-12">
                      <div className="row p-3">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12 p-3">
                      <div className="row mb-3">
                        <h1 className="title-placeholder placeholder"></h1>
                      </div>
                      <div className="row mb-2">
                        <div className="main-box-content text-placeholder placeholder"></div>
                      </div>
                      <div className="row">
                        <div className="main-box-content text-placeholder placeholder"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className="row col-md-2 col-12"></div>
                    <div className="row col-md-8 col-12 ">
                      <div className="row mb-3">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                      <div className="row mb-3">
                        <h2 className="title-placeholder placeholder"></h2>
                      </div>
                      <div className="row mb-2">
                        <div className="main-box-content text-placeholder placeholder"></div>
                      </div>
                      <div className="row">
                        <div className="main-box-content text-placeholder placeholder"></div>
                      </div>
                    </div>
                    <div className="row col-md-2 col-12"></div>
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
        <VideoComponent />
        <SpecialsCarousel title={"Specials"} />
        <SubscribeComponent />
        <ArticlesSectionQueer />
        <div className="flex-center">
          {
            <div id="6644">
              <div class="top-adverties">
                <div id="bannerAd_sas_119619_11111" className="Ad119619"></div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default QueerHomeComponent;
